import { DateTime } from 'luxon';
import { FT_getIsoDate } from './date-functions.js';
export const FT_isBeforeNow = checkDate => {
  const currentIsoDate = DateTime.now().toISODate();
  const checkIso = typeof checkDate === 'string' ? FT_getIsoDate(checkDate) : checkDate.isValid ? checkDate.toISODate() ?? '' : '';
  return checkIso < currentIsoDate;
};
export const FT_isAfterNow = checkDate => {
  const currentIsoDate = DateTime.now().toISODate();
  const checkIso = typeof checkDate === 'string' ? FT_getIsoDate(checkDate) : checkDate.isValid ? checkDate.toISODate() ?? '' : '';
  return checkIso > currentIsoDate;
};
export const FT_isToday = checkDate => {
  const currentIsoDate = DateTime.now().toISODate();
  const checkIso = typeof checkDate === 'string' ? FT_getIsoDate(checkDate) : checkDate.isValid ? checkDate.toISODate() ?? '' : '';
  return checkIso === currentIsoDate;
};

import { CreatableRow, MutableRow } from '@ft/lib/core-lib';
import { ModeType } from '@furnas-technology/angular-library';
import { FilteredRecord, SearchBarField } from '@furnas-technology/common-library/filters';

import { Syllabus } from '@furnas-technology/common-library/syllabuses';

export type SyllabusDialogModel = {
	modeType: ModeType;
	id: string;
};

// -------------------------------
// syllabus
// -------------------------------
export type SyllabusMutateRecord = MutableRow<Syllabus>;
export type SyllabusCreateRecord = CreatableRow<Syllabus>;

export { Syllabus };

export const MaxDisplayAge = 18;
export const MinDisplayAge = 5;

export const SyllabusSearchBarFields: SearchBarField<Syllabus>[] = [
	new SearchBarField<Syllabus>('syllabusName', ['string']),
	new SearchBarField<Syllabus>('_compStyles', ['string']),
	new SearchBarField<Syllabus>('_compRangeDescs', ['string']),
	new SearchBarField<Syllabus>('_compSoloOrGroups', ['string']),
];

export type FilteredSyllabus = FilteredRecord<Syllabus>;

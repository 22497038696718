import { CreatableRow, MutableRow } from '@ft/lib/core-lib';

import { ApiCreateProp, ApiMutateProp } from '@ft/lib/core-lib';
import { CreateRowRequest, GetPagedDataRequest, MutateRowRequest } from '@ft/lib/crud-helper-lib';
import { StoreDefinition } from '@ft/lib/indexeddb-lib';
import { SignalStoreType, createSignalStore } from '@ft/lib/signal-store-lib';
import { Competition } from '@furnas-technology/common-library/competitions';
import { CompetitionSearchBarFields } from '../competition.model';
import { CompetitionEditCardComponent } from '../components/competition-edit-card/competition-edit-card.component';
import { ApiUrls } from '../data/api.constants';
import {
	getManyProperty,
	nameProperty,
	resultProperty,
	signalStoreName,
	sortProperty,
} from '../data/competition.constants';

const getPagedDataRequest: GetPagedDataRequest<Competition> = {
	getManyProperty: getManyProperty,
	getManyApi: ApiUrls.competitions.getCompetitions(),
	chunkSize: 70,
};

const createRowRequest: CreateRowRequest<Competition> = {
	apiCreate: (record: CreatableRow<Competition>) => ApiUrls.competitions.competitionCreateOne(record),
	apiCreateProp: ApiCreateProp,
	row: new Competition(),
	notify: true,
	notifyKey: nameProperty,
};

const mutateRowRequest: MutateRowRequest<Competition> = {
	apiMutate: (row: MutableRow<Competition>) => ApiUrls.competitions.competitionUpdateById(row._id, row as Competition),
	apiMutateProp: ApiMutateProp,
	row: new Competition(),
	notify: true,
	notifyKey: nameProperty,
};

const sortFunction = (a: Competition, b: Competition): number => {
	return (a.competitionName ?? '').toLocaleLowerCase().localeCompare((b.competitionName ?? '').toLocaleLowerCase());
};

const selectById = (competition: Competition) => competition._id;

/**
 * Indexed DB defintions
 */
const COMPETITIONS_STORE: StoreDefinition = {
	tableName: `Competitions`,
	keyPath: '_id',
	autoIncrement: false,
	indexFields: `_id, updatedAt, competitionName, venueLocation, venueName, retrievedAt `,
};

/**
 * Create signal store
 */
export const CompetitionStore: SignalStoreType<Competition> = createSignalStore<Competition>({
	signalStoreName: signalStoreName,
	getPagedDataRequest: getPagedDataRequest,
	createRowRequest: createRowRequest,
	mutateRowRequest: mutateRowRequest,
	apiDelete: ApiUrls.competitions.competitionDeleteById,
	editComponent: CompetitionEditCardComponent,
	editResultProperty: resultProperty,
	nameProperty: nameProperty,
	sortProperty: sortProperty,
	searchBarFields: CompetitionSearchBarFields,
	loadOn: 'subscribe',
	classConstructor: Competition,

	indexedDBConfig: {
		indexedDBName: 'competitions',
		indexedDBVersion: 2.1,
		indexedDBStoreList: [COMPETITIONS_STORE],
	},
});

import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnInit,
	QueryList,
	computed,
	effect,
	inject,
	viewChild,
	viewChildren,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { MatDialogModule } from '@angular/material/dialog';

import type { MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { ClearUnsubscriberFields } from '@danceShared/competitions-shared/competition.model';
import { CompetitionService } from '@danceShared/competitions-shared/data/competition.service';
import { SyllabusGroupsComponent } from '@danceShared/syllabuses-shared/components/syllabus-groups/syllabus-groups.component';
import { AuthStore } from '@ft/lib/auth-lib';
import { GetMutableObject } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { FT_StandardComponent } from '@ft/lib/standard-types-lib';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import { Category } from '@furnas-technology/common-library/categories';
import { Competition } from '@furnas-technology/common-library/competitions';

interface ModelView extends Competition {
	hideRow?: boolean;
}

@Component({
	selector: 'ft-competition-display',
	templateUrl: './competition-display.component.html',
	styleUrls: ['./competition-display.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [
		CloseIconButtonComponent,
		DragDropModule,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatSelectModule,
		NgClass,
		NgStyle,
		NgTemplateOutlet,
		ReactiveFormsModule,
		SyllabusGroupsComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionDisplayComponent extends FT_StandardComponent<Competition> implements OnInit, AfterViewInit {
	protected authStore = inject(AuthStore);
	private env = inject(EnvironmentService);

	private competitionService = inject(CompetitionService);

	matAutocomplete = viewChild<MatAutocomplete>('auto');
	dialogcontent = viewChild<ElementRef>('dialogcontent');

	datefields = viewChildren<QueryList<unknown>>('datefield');
	styles = viewChildren<QueryList<unknown>>('style');
	categorylists = viewChildren<QueryList<unknown>>('categorylist');

	modelViewable = computed<Partial<Competition>>(() => {
		const model = this.model();
		if (!model) return {};
		return this.authStore.isSubscribedOrAdmin() ? model : ClearUnsubscriberFields(model);
	});

	category!: Category;
	filteredData: BehaviorSubject<Competition[]> = new BehaviorSubject<Competition[]>([]);

	entriesOpenDisplay = computed(() => this.modelViewable()?._entriesOpenDisplay || 'TBD');
	entriesCloseDisplay = computed(() => this.modelViewable()?._entriesCloseDisplay || 'TBD');
	entriesLateCloseDisplay = computed(() => this.modelViewable()?._entriesLateCloseDisplay || 'TBD');

	hasStandardStyles = computed(
		() => this.modelViewable()?._syllabus?._styleGroups.findIndex((group) => group.categoryType === 'Standard') !== -1,
	);
	hasChampionshipStyles = computed(
		() =>
			this.modelViewable()?._syllabus?._styleGroups.findIndex((group) => group.categoryType === 'Championship') !== -1,
	);
	hasScholarshipStyles = computed(
		() =>
			this.modelViewable()?._syllabus?._styleGroups.findIndex((group) => group.categoryType === 'Scholarship') !== -1,
	);
	hasImprovisationStyles = computed(
		() =>
			this.modelViewable()?._syllabus?._styleGroups.findIndex((group) => group.categoryType === 'Improvisation') !== -1,
	);
	hasDisabilitiesStyles = computed(
		() =>
			this.modelViewable()?._syllabus?._styleGroups.findIndex(
				(group) => group.categoryType === 'Dancers with Disabilities',
			) !== -1,
	);

	noSyllabusText = computed(() => {
		return this.authStore.isSubscribedOrAdmin() ? `Syllabus not available` : `Please subscribe to view syllabus`;
	});

	competitionLogo = computed(() => {
		const width = this.layout.isGT_small() ? 48 : 38;
		return this.env.imageOrLogo(this.modelViewable()?._displayLogo ?? '', {
			width: width,
			height: 48,
			fit: 'contain',
			background: 'transparent',
			position: 'center',
		});
	});

	logoStyles = computed(() => {
		return { width: this.layout.isGT_small() ? '48px' : '38px', height: '48px' };
	});

	showEntriesOpen = computed(() => {
		return !this.modelViewable()._entriesMinMaxDisplay?.includes(this.entriesOpenDisplay());
	});

	showEntriesClose = computed(() => {
		return !this.modelViewable()._entriesMinMaxDisplay?.includes(this.entriesCloseDisplay());
	});

	showEntriesLateClose = computed(() => {
		if (this.showEntriesClose() && this.entriesCloseDisplay() === this.entriesLateCloseDisplay()) return false;
		return !!(this.modelViewable()._entriesMinMaxDisplay !== this.entriesLateCloseDisplay());
	});

	showAdditionalDates = computed(() => {
		return this.showEntriesOpen() || this.showEntriesClose() || this.showEntriesLateClose();
	});

	showVenue = computed(() => {
		return !!(this.modelViewable().venueName || this.modelViewable().venueLocation);
	});

	showAgeCutoff = computed(() => {
		return !!(
			this.modelViewable().ageCutoff &&
			(this.modelViewable().ageCutoff !== 'Other' || this.modelViewable().ageCutoffNote)
		);
	});

	showFees = computed(() => {
		return !!(this.modelViewable()._competitionFeesDisplay || this.modelViewable()._feesNotesDisplay);
	});

	showPrizes = computed(() => {
		return !!this.modelViewable()?.prizes?.length;
	});

	// @Inject(MAT_DIALOG_DATA) public competitionDialog: CompetitionDialogModel
	constructor() {
		super();

		this.recordName.set('Account');

		/**
		 * load data on input changes
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - configId=${this.configId()}, modeType=${this.modeType()}`);
			const { obj: mutableObject, reference } = GetMutableObject(
				this.modeType(),
				this.configId(),
				Competition,
				this.competitionService.selectDocumentById,
			);
			this.loadModel(mutableObject, reference);
		});
	}

	// async ngOnInit() {
	// 	// get competition
	// 	const { obj: competition, reference } = GetMutableObject(
	// 		'Info',
	// 		this.competitionDialog.id,
	// 		Competition,
	// 		this.competitionService.selectDocumentById,
	// 	);

	// 	this.loadModel(competition, reference);
	// }

	loadModel(competition: Competition, reference: string): void {
		console.debug(`${this.constructor.name} - loadModel - competition=`, competition);

		if (this.authStore.isSubscribedOrAdmin()) {
			this.headerTitle.set(competition.competitionName ?? `Competition Info`);
		} else {
			this.headerTitle.set(`Competition Info`);
		}
		this.model.set(competition);
		// this.cdr.detectChanges();
	} // end loadModel

	setWidths(screenWidth: number): void {}

	getScrubbedLink(link: string, desc: string): string | undefined {
		const trimLink = String(link).trim() ? String(link).trim() : String(desc).trim();
		if (/^http/i.test(trimLink)) return trimLink;
		if (/^mailto/i.test(trimLink)) return trimLink;
		if (/^tel/i.test(trimLink)) return trimLink;

		if (/@/.test(trimLink)) return `mailto:${trimLink}`;

		const phonePattern = /^[\d\s]+$/;
		if (phonePattern.test(trimLink)) return `tel:${trimLink}`;
		if (trimLink.includes('.')) return `https://${trimLink}`;
		return undefined;
	}
} // end class

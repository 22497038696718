import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	type OnInit,
	computed,
	effect,
	inject,
	viewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

import { MatDialogModule } from '@angular/material/dialog';

import { type MatAutocomplete } from '@angular/material/autocomplete';

import {} from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { type FormlyFieldConfig, type FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FormlySelectModule, type FormlySelectOption } from '@ngx-formly/core/select';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';

import {
	LuxonDateAdapter,
	MAT_LUXON_DATE_ADAPTER_OPTIONS,
	MAT_LUXON_DATE_FORMATS,
} from '@angular/material-luxon-adapter';
import { CompetitionService } from '@danceShared/competitions-shared/data/competition.service';
import { AuthStore } from '@ft/lib/auth-lib';
import { GetMutableObject } from '@ft/lib/core-lib';
import { FT_DefaultLanguage } from '@ft/lib/locale-lib';
import { FT_StandardComponent } from '@ft/lib/standard-types-lib';
import {} from '@furnas-technology/angular-library';
import {
	CloseIconButtonComponent,
	ErrorDisplayComponent,
	ProgressBarComponent,
} from '@furnas-technology/angular-library/components';
import { AgeCutoff, Competition } from '@furnas-technology/common-library/competitions';
import { FT_MAT_DATE_FORMATS } from '@furnas-technology/common-library/functions';
import { FormlyMatTextAreaModule } from '@ngx-formly/material/textarea';
import { CompetitionEditCardFields } from './competition-edit-card.formlyfields';

@Component({
	templateUrl: './competition-edit-card.component.html',
	styleUrls: ['./competition-edit-card.component.scss'],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: FT_DefaultLanguage },
		{ provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
		{ provide: LuxonDateAdapter, useValue: { useUtc: false } },
		{ provide: MAT_DATE_FORMATS, useValue: FT_MAT_DATE_FORMATS },
		{ provide: MAT_LUXON_DATE_FORMATS, useValue: FT_MAT_DATE_FORMATS },
		{ provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
	],
	imports: [
		CloseIconButtonComponent,
		DragDropModule,
		ErrorDisplayComponent,
		ProgressBarComponent,
		FontAwesomeModule,
		FormlyMatDatepickerModule,
		FormlyMaterialModule,
		FormlyModule,
		FormlySelectModule,
		FormlyMatTextAreaModule,
		FormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		ReactiveFormsModule,
		MatDialogModule,
		MatButtonModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionEditCardComponent extends FT_StandardComponent<Competition> implements OnInit, AfterViewInit {
	private cdr = inject(ChangeDetectorRef);
	private authStore = inject(AuthStore);
	private competitionService = inject(CompetitionService);

	rowsLoading = computed(() => this.competitionService.isLoading());

	matAutocomplete = viewChild<MatAutocomplete>('auto');

	faCalendarDays = faCalendarDays;

	ageCutoffs = computed(() => {
		const appSettings = this.authStore.selectAppSettings();
		if (!appSettings || !appSettings['ageCutoffs']) return [];
		const resultAgeCutoffs = appSettings['ageCutoffs'] as AgeCutoff[];

		return resultAgeCutoffs
			.filter((value) => !!value)
			.map(
				(value: AgeCutoff) =>
					({
						label: value,
						value: value,
						disabled: false,
					}) as FormlySelectOption,
			);
	});

	fields: FormlyFieldConfig[] = CompetitionEditCardFields(this.ageCutoffs());
	options: FormlyFormOptions = {};

	constructor() {
		super();

		this.recordName.set('Competition');

		/**
		 * load data on input changes
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - configId=${this.configId()}, modeType=${this.modeType()}`);
			const { obj: mutableObject, reference } = GetMutableObject(
				this.modeType(),
				this.configId(),
				Competition,
				this.competitionService.selectDocumentById,
			);
			this.loadModel(mutableObject, reference);
		});
	}

	loadModel(competition: Competition, reference: string): void {
		this.reference = reference;
		this.model.set(competition);
		this.cdr.detectChanges();
	} // end loadModel

	onModelChange(event: unknown): void {
		this.formGroup$.next(this.form);
	}
} // end class

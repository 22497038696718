import { CreatableRow, MutableRow } from '@ft/lib/core-lib';
import { ModeType } from '@furnas-technology/angular-library';
import { Competition, DefaultDateAndNote, DefaultFromToDates } from '@furnas-technology/common-library/competitions';
import { FilteredRecord, SearchBarField } from '@furnas-technology/common-library/filters';

export type CompetitionDialogModel = {
	modeType: ModeType;
	id: string;
};

// -------------------------------
// competition
// -------------------------------
export type CompetitionMutateRecord = MutableRow<Competition>;
export type CompetitionCreateRecord = CreatableRow<Competition>;

export const CompetitionSearchBarFields: SearchBarField<Competition>[] = [
	new SearchBarField<Competition>('competitionName', ['string']),
	new SearchBarField<Competition>('venueName', ['string']),
	new SearchBarField<Competition>('venueLocation', ['string']),
	new SearchBarField<Competition>('_addressDisplay', ['string']),
	new SearchBarField<Competition>('_linkSearch', ['string']),
	new SearchBarField<Competition>('_city', ['string']),
	new SearchBarField<Competition>('_state', ['string']),
	new SearchBarField<Competition>('_statecode', ['string']),
	new SearchBarField<Competition>('_syllabus._compStyles', ['string']),
	new SearchBarField<Competition>('_syllabus._compSoloOrGroups', ['string']),
	new SearchBarField<Competition>('_syllabus._compRangeDescs', ['string']),
	new SearchBarField<Competition>('_syllabus._performanceTimes', ['string']),
	new SearchBarField<Competition>('_syllabus._sections', ['string']),
	new SearchBarField<Competition>('_competitionYear', ['year']),
	new SearchBarField<Competition>('feesNotes', ['string']),
];

export type FilteredCompetition = FilteredRecord<Competition>;

export const ClearUnsubscriberRecords = (competitions: Competition[]): Competition[] => {
	const clearedComps: Competition[] = [];
	for (const comp of competitions) {
		clearedComps.push(ClearUnsubscriberFields(comp));
	}

	return clearedComps;
};

const SubscribeToViewText = '* Subscribe to View *';

export const ClearUnsubscriberFields = (competition: Competition): Competition => {
	return {
		...competition,
		competitionName: SubscribeToViewText,
		_syllabus: undefined,
		syllabus_ids: [],
		links: [],
		additionalInfo: '',
		entriesOpen: [],
		entriesClose: [],
		entriesLateClose: [],
		musicSubmission: [],

		_entriesOpenDisplay: SubscribeToViewText,
		_entriesCloseDisplay: SubscribeToViewText,
		_entriesMinMaxDisplay: SubscribeToViewText,
		_musicSubmissionDisplay: SubscribeToViewText,

		ageCutoff: '',
		ageCutoffNote: '',
		competitionFees: [],
		feesNotes: [],
		_competitionFeesDisplay: SubscribeToViewText,
		_feesNotesDisplay: SubscribeToViewText,
		prizes: [],
		_competitionDatesMinMax: { ...DefaultFromToDates },
		_entriesOpenMin: { ...DefaultDateAndNote },
		_entriesCloseMax: { ...DefaultDateAndNote },
		_entriesLateCloseMax: { ...DefaultDateAndNote },

		_competitionDatesDisplay: SubscribeToViewText,
		_competitionDatesMinMaxDisplay: SubscribeToViewText,
		_competitionDatesDisplayArray: [SubscribeToViewText],

		_entriesOpenMinDisplay: SubscribeToViewText,
		_entriesCloseMaxDisplay: SubscribeToViewText,
		_entriesLateCloseDisplay: SubscribeToViewText,
		_entriesLateCloseMaxDisplay: SubscribeToViewText,
	};
};

export class CompetitionList {
	_id: string = '';

	competitionName: string = '';
	competitionLogo: string = '';
	venueName: string = '';
	venueLocation: string = '';
	datesStatus: string = '';
	entriesOpen: string = '';
	entriesClose: string = '';
	entriesLateClose: string = '';
	competitionDates: string = '';
	musicSubmission: string = '';
	_addressDisplay: string = '';

	createdAt: string = '';
	updatedAt: string = '';
	deleted: string = '';
	retrievedAt: string = '';
	constructor(init?: Partial<CompetitionList>) {
		Object.assign(this, init);
	}
}

import { ModeType } from '@furnas-technology/angular-library';
import { Category, StandardiseCategory } from '@furnas-technology/common-library/categories';

// export const Ages: string[] = ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18'];

// -------------------------------
// category
// -------------------------------

export interface CategoryDialogModel {
	modeType: ModeType;
	id: string;
}

export const CategoryDisplayFn = (record: Category): string => {
	const updatedCategory = { ...record } as Category;
	StandardiseCategory(updatedCategory);
	return updatedCategory._fullStyle;
};

import { gql } from 'apollo-angular';

import { Syllabus, SyllabusCreateRecord, SyllabusMutateRecord } from '@danceShared/syllabuses-shared/syllabus.model';
import { FT_propsRecord } from '@ft/lib/crud-helper-lib';

export const syllabusQueryFields = [
	'syllabusName',
	'categories',
	'_competition_ids',
	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
	'_styleGroups',
	'_compStyles',
	'_compSoloOrGroups',
	'_performanceTimes',
	'_sections',
	'_divisions',
	'_compRangeDescs',
];

export const syllabusMutateFields: (keyof Syllabus)[] = ['syllabusName', 'categories', 'deleted'];

export const ApiUrls = {
	syllabus: {
		totals: () => gql`
      query {
        totals: syllabusTotals
      }
    `,

		getSyllabuses: () => gql`
      query($filter: FilterFindManySyllabusInput, $sort: SortFindManySyllabusInput, $skip: Int, $limit: Int) {
        syllabusCount
        syllabusMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
          _id
          ${syllabusQueryFields.join(`\r\n`)}
        }
      }
    `,

		syllabusCreateOne: (record: SyllabusCreateRecord) => {
			const mutateRecord = FT_propsRecord(record);

			return {
				mutation: gql`
          mutation ($record: CreateOneSyllabusInput!) {
            createResult: syllabusCreateOne(record: $record) {
              recordId
              record {
                _id
                ${syllabusQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { record: mutateRecord },
			};
		},

		syllabusUpdateById: (_id: string, record: SyllabusMutateRecord) => {
			const mutateRecord = FT_propsRecord(record);

			return {
				mutation: gql`
          mutation ($_id: MongoID!, $record: UpdateByIdSyllabusInput!) {
            mutateResult: syllabusUpdateById(_id: $_id, record: $record) {
              recordId
              record {
                _id
                ${syllabusQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { _id: _id, record: mutateRecord },
			};
		},

		syllabusDeleteById: (_id: string) => {
			return {
				mutation: gql`
          mutation ($input: DocumentIdInputInput!) {
            deleteResult: syllabusDeleteById(input: $input)
          }
        `,
				variables: { input: { documentId: `${_id}` } },
			};
		},
	},
};

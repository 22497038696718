import { Injectable, computed, inject, linkedSignal } from '@angular/core';
import { AuthStore } from '@ft/lib/auth-lib';
import { GroupByProperty } from '@ft/lib/facade-lib';

import { GenericStoreService, GenericStoreServiceInput } from '@ft/lib/store-service-lib';
import { CompetitionDisplayComponent } from '../components/competition-display/competition-display.component';

import { ImageFit } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { Age, AgeType, KThru12 } from '@furnas-technology/common-library/ages';
import {
	BaseCompStyle,
	CategoryType,
	CompSoloOrGroup,
	CompStyle,
	Section,
} from '@furnas-technology/common-library/categories';
import { Competition, EntryRange } from '@furnas-technology/common-library/competitions';
import {
	DateRange,
	DateRanges,
	FT_replaceStringFirst,
	ImagePosition,
} from '@furnas-technology/common-library/functions';
import { IdToString } from '@furnas-technology/common-library/models';
import { Syllabus } from '@furnas-technology/common-library/syllabuses';
import { DateTime } from 'luxon';
import { CompetitionEditCardComponent } from '../components/competition-edit-card/competition-edit-card.component';
import { CompetitionStore } from '../store/competition.store';
import { UnsubscribedCompetitionStore } from '../store/unsubscribed-competition.store';
import { nameProperty, resultProperty, signalStoreName } from './competition.constants';

type ImageConfig = {
	width?: number;
	height?: number;
	fit?: ImageFit;
	position?: ImagePosition;
};

const defaultImageConfig: ImageConfig = {
	width: 80,
	height: 80,
	fit: 'contain',
	position: 'center',
};

const genericStoreServiceInput: GenericStoreServiceInput<Competition> = {
	signalStoreName: signalStoreName,
	signalStore: CompetitionStore,

	viewComponent: CompetitionDisplayComponent,
	editComponent: CompetitionEditCardComponent,
	resultProperty: resultProperty,
	nameProperty: nameProperty,
};

type ExternalIdMap = {
	[key: string]: string | string | number;
};
type ExternalCount = ExternalIdMap & { count: number };

export type ExternalCountMap = {
	[key: string]: ExternalCount;
};

@Injectable({
	providedIn: 'root',
})
export class CompetitionService extends GenericStoreService<Competition> {
	private authStore = inject(AuthStore);
	private subscribedCompetitionStore = inject(CompetitionStore);
	private unsubscribedCompetitionStore = inject(UnsubscribedCompetitionStore);
	private env = inject(EnvironmentService);

	/**
	 * store selector
	 */
	private store = linkedSignal(() => {
		const compStoreName = this.authStore.isSubscribedOrAdmin()
			? 'subscribedCompetitionStore'
			: 'unsubscribedCompetitionStore';

		const compStore = this.authStore.isSubscribedOrAdmin()
			? this.subscribedCompetitionStore
			: this.unsubscribedCompetitionStore;

		return compStore;
	});

	override isLoading = computed(() => this.store().isLoading());
	selectLoadStatus = this.store().selectLoadStatus;
	selectedId = this.store().selectedId;
	selectedDocument = this.store().selectedDocument;

	selectDocumentById = (id: string) => this.store().selectDocumentById(id);
	setSelectedId = this.store().setSelectedId;
	setOverrideSearchString = this.store().setOverrideSearchString;

	override documents = computed(() => this.store().documents());
	override filteredDocumentsNotOmitted = computed(() => this.store().filteredDocumentsNotOmitted());
	filteredDocuments = computed(() => this.store().filteredDocuments());

	filteredDocumentsNotOmittedLength = linkedSignal(() => this.filteredDocumentsNotOmitted().length);
	documentsLength = linkedSignal(() => this.documents().length);
	allCompetitions = computed(() => this.documents());

	filteredDocumentsNotOmittedByCompetitionStartDate = computed(() => {
		return this.filteredDocumentsNotOmitted().sort((a, b) => {
			if (!!a._competitionDatesMinMax.fromDate && !!b._competitionDatesMinMax.fromDate) {
				return a._competitionDatesMinMax.fromDate > b._competitionDatesMinMax.fromDate ? 1 : -1;
			} else {
				return a.competitionName.localeCompare(b.competitionName);
			}
		});
	});

	/**
	 * Competitions by status
	 */
	currentDate = DateTime.now().toISODate();
	fourWeeksFromNow = DateTime.now().plus({ days: 28 }).toISODate();
	threeMonthsFromNow = DateTime.now().plus({ months: 3 }).toISODate();
	currentYear = DateTime.now().year;
	nextYear = DateTime.now().plus({ years: 1 }).year;

	compDatesNextThreeMonths = computed(() => {
		const rows = this.documents().filter((x) => x._dateRanges.includes('Next 3 Months'));
		return rows;
	});

	compDatesLaterThisYear = computed(() => {
		const rows = this.documents().filter((x) => x._dateRanges.includes('Later This Year'));
		return rows;
	});

	compDatesNextYear = computed(() => {
		const rows = this.documents().filter((x) => x._dateRanges.includes('Next Year'));
		return rows;
	});

	entryCurrentlyOpen = computed(() => {
		const rows = this.documents().filter((x) => x._entryRanges.includes('Currently In Progress'));
		return rows;
	});

	entryOpenNextThreeMonths = computed(() => {
		const rows = this.documents().filter((x) => x._entryRanges.includes('Next 3 Months'));
		return rows;
	});

	entryOpenLaterInTheYear = computed(() => {
		const rows = this.documents().filter((x) => x._entryRanges.includes('Later This Year'));
		return rows;
	});

	entryOpenNextYear = computed(() => {
		const rows = this.documents().filter((x) => x._entryRanges.includes('Next Year'));
		return rows;
	});

	/**
	 *  virtual fields
	 */
	searchCompetitions = computed(() => this.filteredDocuments().map((x) => x.record));

	competitionNames = computed(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['competitionName'], 'notOmitted');
		const values = GroupByProperty(rows, 'competitionName');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	filteredAgeTypes = computed<AgeType[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_ageTypes'], 'notOmitted');
		const values = GroupByProperty(rows, '_ageTypes');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	filteredAges = computed<Age[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_ages'], 'notOmitted');
		const values = GroupByProperty(rows, '_ages');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort((a, b) => (Number(FT_replaceStringFirst(a, '+', '')) > Number(FT_replaceStringFirst(b, '+', '')) ? 1 : -1));
	});

	filteredYears = computed<KThru12[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_schoolYears'], 'notOmitted');
		const values = GroupByProperty(rows, '_schoolYears');
		const valueArray = Array.from(values);

		return valueArray.sort((a, b) => {
			const aValue = a === 'K' || a === 'Prep' ? 0 : Number(a);
			const bValue = b === 'K' || a === 'Prep' ? 0 : Number(b);
			return aValue > bValue ? 1 : -1;
		});
	});

	venueLocations = computed<string[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_formattedAddress'], 'notOmitted');
		const values = GroupByProperty(rows, '_formattedAddress');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	countrycodes = computed<string[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_countrycode'], 'notOmitted');
		const values = GroupByProperty(rows, '_countrycode');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	statecodes = computed<string[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_statecode'], 'notOmitted');
		const values = GroupByProperty(rows, '_statecode');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	cities = computed<string[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_city'], 'notOmitted');
		const values = GroupByProperty(rows, '_city');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	compStyles = computed<CompStyle[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_compStyles'], 'notOmitted');
		const syllabusArray = this.getSyllabusesFromCompetitions(rows);
		const values = GroupByProperty(syllabusArray, '_compStyles');
		return Array.from(values).sort();
	});

	baseCompStyles = computed<BaseCompStyle[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_baseCompStyles'], 'notOmitted');
		const syllabusArray = this.getSyllabusesFromCompetitions(rows);
		const values = GroupByProperty(syllabusArray, '_baseCompStyles');
		return Array.from(values).sort();
	});

	categoryTypes = computed<CategoryType[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_categoryTypes'], 'notOmitted');
		const syllabusArray = this.getSyllabusesFromCompetitions(rows);
		const values = GroupByProperty(syllabusArray, '_categoryTypes');
		return Array.from(values).sort();
	});

	compSoloOrGroups = computed<CompSoloOrGroup[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_compSoloOrGroups'], 'notOmitted');
		const syllabusArray = this.getSyllabusesFromCompetitions(rows);
		const values = GroupByProperty(syllabusArray, '_compSoloOrGroups');
		return Array.from(values).sort();
	});

	rangeDescs = computed<string[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_compRangeDescs'], 'notOmitted');
		const syllabusArray = this.getSyllabusesFromCompetitions(rows);
		const values = GroupByProperty(syllabusArray, '_compRangeDescs');
		return Array.from(values).sort();
	});

	entryRanges = computed<EntryRange[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_entryRanges'], 'notOmitted');
		const values = GroupByProperty(rows, '_entryRanges');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort((a, b) => (DateRanges.findIndex((x) => x === a) > DateRanges.findIndex((x) => x === b) ? 1 : -1));
	});

	dateRanges = computed<DateRange[]>(() => {
		console.debug(`${this.constructor.name} - dateRanges - searchCompetitions=${this.searchCompetitions().length}`);
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_dateRanges'], 'notOmitted');
		const values = GroupByProperty(rows, '_dateRanges');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort((a, b) => (DateRanges.findIndex((x) => x === a) > DateRanges.findIndex((x) => x === b) ? 1 : -1));
	});

	sections = computed<Section[]>(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchCompetitions(), ['_baseCompStyles'], 'notOmitted');
		const syllabusArray = this.getSyllabusesFromCompetitions(rows);
		const values = GroupByProperty(syllabusArray, '_sections');
		return Array.from(values).sort();
	});

	constructor() {
		super(genericStoreServiceInput);
	}

	getSyllabusesFromCompetitions(rows: Competition[]): Syllabus[] {
		const syllabusArray: Syllabus[] = [];
		for (const comp of rows) {
			if (comp._syllabus) {
				syllabusArray.push(comp._syllabus);
			}
		}
		return syllabusArray;
	}

	selectGroupedBySyllabusId = computed(() => {
		const competitions = this.documents();
		const groupedEntities = competitions.reduce((acc, entity) => {
			if (entity.syllabus_ids.length) {
				for (const externalId of entity.syllabus_ids) {
					const externalIdString = IdToString(externalId);
					if (acc[externalIdString]) {
						acc[externalIdString].count += 1;
					} else {
						acc[externalIdString] = { syllabusId: externalIdString, count: 1 };
					}
				}
			}

			return acc;
		}, {} as ExternalCountMap);
		return groupedEntities;
	});

	/**
	 * Counts
	 */
	selectSyllabusCount(syllabusId: string): number {
		if (!syllabusId) return 0;
		const idString = IdToString(syllabusId);

		return this.selectGroupedBySyllabusId()[idString]?.count ?? 0;
	}

	selectOrganisationCount(organisationId: string): number {
		if (!organisationId) return 0;
		const idString = IdToString(organisationId);

		const selectedComps = this.documents().filter((comp) => IdToString(comp._organisation?._id ?? '') === idString);
		return selectedComps.length;
	}

	/**
	 * competition logo
	 */
	competitionLogo(url: string, config: ImageConfig): string {
		const imageConfig = { ...defaultImageConfig, ...config };
		if (!url) return '';

		return this.env.imageOrLogo(url, { ...imageConfig });
	}
} // end class

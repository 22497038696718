import { DateTime } from 'luxon';
import { NotesArrayToString } from '../functions/array-functions.js';
import { FT_isAfterNow, FT_isBeforeNow } from '../functions/date-compare-functions.js';
import { FT_FromToDate, FT_displayDate, FT_getIsoDate, GetFromToDatesDisplay, getDateRanges } from '../functions/date-functions.js';
import { FT_isEmpty, GetMax, GetMin } from '../functions/miscellaneous-functions.js';
import { DateAndNoteDisplay, ScrubDateAndNote } from './date-and-note.js';
import { ScrubFromToDates } from './from-to-date.js';
export const getAddressDisplay = (geoTag, venueLocation) => {
  return (geoTag?.formattedAddress || venueLocation) ?? '';
};
export const getVenueDisplay = (venueName, addressDisplay) => {
  // venue display
  let venueDisplay = '';
  if (venueName && !venueName.match(/^tbc$/i)) {
    venueDisplay = venueName;
  }
  if (addressDisplay) {
    venueDisplay += `${venueDisplay ? '\n' : ''}${addressDisplay}`;
  }
  if (!venueDisplay) venueDisplay = `tbc`;
  return venueDisplay.trim();
};
export const isCompetitionOpen = (fromDate, toDate) => {
  // if no dates, then consider it open
  if (!fromDate && !toDate) return true;
  // get Iso dates
  const fromIsoDate = FT_getIsoDate(fromDate ?? '');
  const toIsoDate = FT_getIsoDate(toDate ?? '');
  if (!fromIsoDate && !toIsoDate) return true;
  // if one date invalid or missing, use the other for both
  let fromDateTime = DateTime.fromISO(fromIsoDate);
  let toDateTime = DateTime.fromISO(toIsoDate);
  if (!fromDateTime.isValid && !toDateTime.isValid) return true;
  // default if one date is invalid
  if (!fromDateTime.isValid) {
    fromDateTime = toDateTime;
  } else if (!toDateTime.isValid) {
    toDateTime = fromDateTime;
  }
  // get current date
  const curDateTime = DateTime.local();
  // prior event
  if (curDateTime > toDateTime) return false;
  return true;
};
export const isEntryOpen = (competitionMinMaxDates, entriesOpenMinDate, entriesCloseMaxDate, entriesLateCloseMaxDate) => {
  if (!competitionMinMaxDates) false;
  // check if competition has started, then not open
  const compMin = (competitionMinMaxDates.fromDate || competitionMinMaxDates.toDate) ?? '';
  if (compMin && FT_isBeforeNow(compMin)) return false;
  // check if entry open date and it is after today, then not open
  if (entriesOpenMinDate && FT_isAfterNow(entriesOpenMinDate)) return false;
  // check if close & late close prior to today, then not open
  if (entriesCloseMaxDate && entriesLateCloseMaxDate) {
    const maxClose = entriesLateCloseMaxDate > entriesCloseMaxDate ? entriesLateCloseMaxDate : entriesCloseMaxDate;
    if (FT_isBeforeNow(maxClose)) return false;
  } else if (entriesCloseMaxDate && FT_isBeforeNow(entriesCloseMaxDate)) {
    return false;
  } else if (entriesLateCloseMaxDate && FT_isBeforeNow(entriesLateCloseMaxDate)) {
    return false;
  }
  // it must be open
  return true;
};
export const getSpecialEntryRanges = dateAndNote => {
  if (!dateAndNote || !Array.isArray(dateAndNote)) return [];
  const ranges = [];
  for (const dn of dateAndNote) {
    if (dn.notepart && typeof dn.notepart === 'string') {
      if (dn.notepart.match(/tbd/i)) ranges.push('TBD');
      if (dn.notepart.match(/\b(full)/i)) ranges.push('Until Full');
    }
  }
  return ranges;
};
/**
 * Entry Dates
 */
export const GetEntryMinMaxDisplay = source => {
  const openDate = source._entriesOpenMin?.datepart ?? '';
  const closeMax = source._entriesCloseMax?.datepart ?? '';
  const lateCloseMax = source._entriesLateCloseMax?.datepart ?? '';
  const maxDate = lateCloseMax && lateCloseMax > closeMax ? lateCloseMax : closeMax;
  // if no dates, then check if full or TBD
  let result = 'TBD';
  if (openDate && maxDate) {
    result = FT_FromToDate(openDate, maxDate, 'medium');
  } else if (openDate) {
    result = `Open ${FT_displayDate(openDate, 'medium')}`;
  } else if (maxDate) {
    result = `Close ${FT_displayDate(maxDate, 'medium')}`;
  } else if (source.entriesOpen.length && source.entriesOpen.findIndex(item => item.notepart?.match(/\b(full)/i)) > -1) {
    result = `Close When Full`;
  } else if (source.entriesClose.length && source.entriesClose.findIndex(item => item.notepart?.match(/\b(full)/i)) > -1) {
    result = `Close When Full`;
  }
  return result;
};
export const calculateSyllabusVirtuals = competition => {
  try {
    if (!competition._syllabus) return;
    // syllabus
    competition._syllabusNames = competition._syllabus?.syllabusName ?? '';
    const syllabus = competition._syllabus;
    competition._displayStyles = Array.isArray(syllabus._displayStyles) ? syllabus._displayStyles : [];
    competition._compStyles = Array.isArray(syllabus._compStyles) ? syllabus._compStyles : [];
    competition._baseCompStyles = Array.isArray(syllabus._baseCompStyles) ? syllabus._baseCompStyles : [];
    competition._categoryTypes = Array.isArray(syllabus._categoryTypes) ? syllabus._categoryTypes : [];
    competition._compSoloOrGroups = Array.isArray(syllabus._compSoloOrGroups) ? syllabus._compSoloOrGroups : [];
    competition._performanceTimes = Array.isArray(syllabus._performanceTimes) ? syllabus._performanceTimes : [];
    competition._sections = Array.isArray(syllabus._sections) ? syllabus._sections : [];
    competition._divisions = Array.isArray(syllabus._divisions) ? syllabus._divisions : [];
    competition._compRangeDescs = Array.isArray(syllabus._compRangeDescs) ? syllabus._compRangeDescs : [];
    // ages and schoolYears
    const ageTypeSet = new Set();
    const yearSet = new Set();
    const ageSet = new Set();
    for (const category of syllabus.categories) {
      if (category._ageType) {
        ageTypeSet.add(category._ageType);
      }
      if (category._ageType === 'Age') {
        for (const age of category._ages) {
          ageSet.add(age);
        }
      }
      if (category._ageType === 'School Year') {
        for (const schoolYear of category._schoolYears) {
          yearSet.add(schoolYear);
        }
      }
    }
    competition._ageTypes = Array.from(ageTypeSet);
    competition._ages = Array.from(ageSet);
    competition._schoolYears = Array.from(yearSet);
    competition._ages.sort();
    competition._schoolYears.sort();
    competition._ageTypes.sort();
  } catch (err) {
    console.error(`❌ calculateSyllabusVirtuals:`, err);
  }
};
export const calculateCompetitionVirtuals = competition => {
  // search fields
  if (competition.links.length) {
    competition._linkSearch = competition.links.map(x => x.url).join(', ');
  }
  // address and venue display
  competition._addressDisplay = getAddressDisplay(competition._geoTag, competition.venueLocation);
  competition.venueLocation = competition._addressDisplay;
  competition._venueDisplay = getVenueDisplay(competition.venueName, competition._addressDisplay);
  competition._city = competition?._geoTag?.city ?? '';
  competition._state = competition?._geoTag?.state || competition?._geoTag?.country || '';
  competition._statecode = competition?._geoTag?.statecode || competition?._geoTag?.country || '';
  competition._country = competition?._geoTag?.country ?? '';
  competition._countrycode = competition?._geoTag?.countrycode ?? '';
  competition._postcode = competition?._geoTag?.postcode ?? '';
  competition._formattedAddress = competition?._geoTag?.formattedAddress ?? '';
  /**
   * Competition Dates Min Max
   */
  const min = GetMin(competition.competitionDates ?? [], 'fromDate') ?? '';
  const max = GetMax(competition.competitionDates ?? [], 'toDate') ?? '';
  competition._competitionDatesMinMax = {
    fromDate: min,
    toDate: max,
    notes: ''
  };
  competition._competitionYear = competition._competitionDatesMinMax?.fromDate ? Number(competition._competitionDatesMinMax?.fromDate.slice(0, 4)) : 0;
  competition._competitionEarliestStartDate = min;
  competition._competitionLatestEndDate = max;
  competition._competitionDatesMinMaxDisplay = FT_FromToDate(competition._competitionDatesMinMax?.fromDate ?? '', competition._competitionDatesMinMax?.toDate ?? '', 'medium') || '*N/A*';
  // competition date ranges
  const compFromDate = competition._competitionDatesMinMax.fromDate ?? '';
  const compToDate = competition._competitionDatesMinMax.toDate ?? '';
  competition._dateRanges = getDateRanges(compFromDate, compToDate) ?? [];
  competition._competitionOpen = isCompetitionOpen(competition._competitionDatesMinMax.fromDate, competition._competitionDatesMinMax.toDate);
  /**
   * Competition Dates Display
   */
  competition._competitionDatesDisplay = GetFromToDatesDisplay(competition?.competitionDates ?? [], 'single');
  competition._competitionDatesDisplayArray = GetFromToDatesDisplay(competition?.competitionDates ?? [], 'array');
  /**
   * Entries Open Display
   */
  competition._entriesOpenDisplay = DateAndNoteDisplay(competition.entriesOpen) || 'TBD';
  const openMin = GetMin(competition.entriesOpen ?? [], 'datepart') ?? '';
  competition._entriesOpenMin = {
    datepart: openMin,
    notepart: ''
  };
  competition._entryYear = competition._entriesOpenMin?.datepart ? Number(competition._entriesOpenMin?.datepart.slice(0, 4)) : 0;
  competition._entriesOpenMinDisplay = FT_displayDate(competition._entriesOpenMin?.datepart ?? '', 'medium');
  /**
   * Entries Close Display
   */
  competition._entriesCloseDisplay = DateAndNoteDisplay(competition.entriesClose) || 'TBD';
  const closeMax = GetMax(competition.entriesClose ?? [], 'datepart') ?? '';
  competition._entriesCloseMax = {
    datepart: closeMax,
    notepart: ''
  };
  competition._entriesCloseMaxDisplay = FT_displayDate(competition._entriesCloseMax?.datepart ?? '', 'medium');
  competition._entriesEarliestStartDate = openMin;
  competition._entriesLatestEndDate = closeMax;
  /**
   * Entries Late Close Display
   */
  competition._entriesLateCloseDisplay = DateAndNoteDisplay(competition.entriesLateClose) || 'TBD';
  const lateCloseMax = GetMax(competition.entriesLateClose ?? [], 'datepart') ?? '';
  competition._entriesLateCloseMax = {
    datepart: lateCloseMax,
    notepart: ''
  };
  competition._entriesLateCloseMaxDisplay = FT_displayDate(competition._entriesLateCloseMax?.datepart ?? '', 'medium');
  // Entries min max display
  competition._entriesMinMaxDisplay = GetEntryMinMaxDisplay(competition);
  competition._entryRanges = getDateRanges(competition._entriesOpenMin.datepart, competition._entriesCloseMax.datepart);
  competition._entryOpen = isEntryOpen(competition._competitionDatesMinMax, competition._entriesOpenMin.datepart ?? '', competition._entriesCloseMax.datepart ?? '', competition._entriesLateCloseMax.datepart ?? '');
  // Music Submission Display
  competition._musicSubmissionDisplay = DateAndNoteDisplay(competition.musicSubmission) || 'TBD';
  /**
   * Fee display fields
   */
  competition._feesNotesDisplay = NotesArrayToString(competition.feesNotes ?? []);
  competition._competitionFeesDisplay = NotesArrayToString(competition.competitionFees ?? []);
  if (competition.ageCutoff === 'Other') {
    competition._ageCutoffDisplay = FT_isEmpty(competition.ageCutoffNote) ? '' : competition.ageCutoffNote;
  } else {
    competition._ageCutoffDisplay = competition.ageCutoff ?? '';
  }
}; // end calculateCompetitionVirtuals
export const ScrubCompetition = competition => {
  ScrubFromToDates(competition.competitionDates);
  ScrubDateAndNote(competition.entriesOpen);
  ScrubDateAndNote(competition.entriesClose);
  ScrubDateAndNote(competition.entriesLateClose);
  ScrubDateAndNote(competition.musicSubmission);
  competition.ageCutoffNote = FT_isEmpty(competition.ageCutoffNote) ? '' : String(competition.ageCutoffNote).trim();
  // default logo
  if (typeof competition.competitionLogo !== 'string') competition.competitionLogo = '';
};

import { CreatableRow, MutableRow } from '@ft/lib/core-lib';

import { ApiCreateProp, ApiMutateProp } from '@ft/lib/core-lib';
import { CreateRowRequest, GetPagedDataRequest, MutateRowRequest } from '@ft/lib/crud-helper-lib';
import { StoreDefinition } from '@ft/lib/indexeddb-lib';
import { SignalStoreType, createSignalStore } from '@ft/lib/signal-store-lib';
import { SyllabusCardComponent } from '../components/syllabus-card/syllabus-card.component';
import { ApiUrls } from '../data/api.constants';
import {
	getManyProperty,
	nameProperty,
	resultProperty,
	signalStoreName,
	sortProperty,
} from '../data/syllabus.constants';
import { Syllabus, SyllabusSearchBarFields } from '../syllabus.model';

const getPagedDataRequest: GetPagedDataRequest<Syllabus> = {
	getManyProperty: getManyProperty,
	getManyApi: ApiUrls.syllabus.getSyllabuses(),
	chunkSize: 75,
};

const createRowRequest: CreateRowRequest<Syllabus> = {
	apiCreate: (record: CreatableRow<Syllabus>) => ApiUrls.syllabus.syllabusCreateOne(record),
	apiCreateProp: ApiCreateProp,
	row: new Syllabus(),
	notify: true,
	notifyKey: nameProperty,
};

const mutateRowRequest: MutateRowRequest<Syllabus> = {
	apiMutate: (row: MutableRow<Syllabus>) => ApiUrls.syllabus.syllabusUpdateById(row._id, row as Syllabus),
	apiMutateProp: ApiMutateProp,
	row: new Syllabus(),
	notify: true,
	notifyKey: nameProperty,
};

const sortFunction = (a: Syllabus, b: Syllabus): number => {
	return (a.syllabusName ?? '').toLocaleLowerCase().localeCompare((b.syllabusName ?? '').toLocaleLowerCase());
};

const selectById = (syllabus: Syllabus) => syllabus._id;

/**
 * Indexed DB defintions
 */
export const SYLLABUSES_STORE: StoreDefinition = {
	tableName: `Syllabuses`,
	keyPath: '_id',
	autoIncrement: false,
	indexFields: `_id, updatedAt, syllabusName, retrievedAt`,
};

/**
 * Create signal store
 */
export const SyllabusStore: SignalStoreType<Syllabus> = createSignalStore<Syllabus>({
	signalStoreName: signalStoreName,
	getPagedDataRequest: getPagedDataRequest,
	createRowRequest: createRowRequest,
	mutateRowRequest: mutateRowRequest,
	apiDelete: ApiUrls.syllabus.syllabusDeleteById,
	editComponent: SyllabusCardComponent,
	editResultProperty: resultProperty,
	nameProperty: nameProperty,
	sortProperty: sortProperty,
	searchBarFields: SyllabusSearchBarFields,
	loadOn: 'subscribe',
	classConstructor: Syllabus,

	indexedDBConfig: {
		indexedDBName: 'syllabuses',
		indexedDBVersion: 1.6,
		indexedDBStoreList: [SYLLABUSES_STORE],
	},
});

import { FT_isEmpty } from '../functions/index.js';
import { FT_replaceStringAll } from '../functions/string-functions.js';
export const KThru12Years = {
  Infant: -3,
  Pre: -2,
  K: -1,
  Prep: -1,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  '10': 10,
  '11': 11,
  '12': 12
};
export const MaxSchoolYear = '12';
export const MinSchoolYear = 'K';
export const MaxSchoolYearNumber = 12;
export const AgeValues = {
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  '10': 10,
  '11': 11,
  '12': 12,
  '13': 13,
  '14': 14,
  '15': 15,
  '16': 16,
  '17': 17,
  '18': 18,
  '18+': 99
};
export const MaxAgeValue = '18';
export const MinAgeValue = '4';
export const MinAgeNumber = 4;
export const MaxAgeNumber = 18;
/**
 * constants
 */
export const checkAge = ageDesc => String(ageDesc).replace(/to|thru|between|pre|senior|junior|teen|post|to|thru|between/gi, ' ').trim();
export const overPattern = /\bo\b|over|plus|\bup\b|minimum|\d+o\b|\d+ovr\b|\d+\+|\d+\s*\+/i;
export const containsOverPattern = ageDesc => overPattern.test(ageDesc);
export const underPattern = /\bu\b|\bund\b|under|less|maximum|minus|below|\d+u\b|\d+und\b|\d+\-|\d+\s*\-/i;
export const containsUnderPattern = ageDesc => underPattern.test(ageDesc);
export const GetAgeFromNumber = ageNumber => {
  if (ageNumber < MinAgeNumber) {
    return MinAgeValue;
  } else {
    return `${String(ageNumber)}`;
  }
};
export const GetSchoolYearFromNumber = yearNumber => {
  const entry = Object.entries(KThru12Years).find(item => item[1] === yearNumber);
  if (!entry) return undefined;
  return entry[0];
};
const addNumberRanges = (fromAge, toAge, ages) => {
  for (let i = fromAge; i <= toAge; i++) {
    ages.push(i);
  }
};
export const GetNumberFromAge = age => {
  const ageNumber = age !== '' ? AgeValues[age] ?? Number(FT_replaceStringAll(age, '+', '')) : 0;
  if (Number.isNaN(ageNumber)) return 0;
  return ageNumber;
};
export const GetNumberFromSchoolYear = schoolYear => {
  const schoolYearNumber = schoolYear !== '' ? KThru12Years[schoolYear] ?? 0 : 0;
  return schoolYearNumber;
};
export const SortableNumber = value => {
  return value.toFixed().padStart(5, '0');
};
export const isAgeType = ageType => {
  return ['Age', 'Junior', 'Senior'].includes(ageType);
};
export const ScrubAgeDesc = ageDesc => {
  return ageDesc.replace(/[^\w+-]/g, ' ').replace(/\s+/g, ' ');
  // return ageDesc.replace(/\W+/g, ' ').replace(/\s+/g, ' ');
};
export const InitCategoryAgeValues = category => {
  category._ageType = 'Age';
  category._compRangeDesc = '';
  category._fromAge = '';
  category._toAge = '';
  category._ages = [];
  category._fromYear = '';
  category._toYear = '';
  category._schoolYears = [];
  category._ageDirective = '';
};
export const ComputeAgeValues = category => {
  try {
    InitCategoryAgeValues(category);
    if (FT_isEmpty(category.ageDesc)) return;
    const ageDesc = String(category.ageDesc).toLowerCase().trim();
    const scrubbedAgeDesc = ScrubAgeDesc(ageDesc);
    // school year or age
    const kAndYear = /(?=.*\bk\b)(?=.*\b(year|yr|years|yrs)\b).*/i;
    const kAndNumber = /(?=.*\bk\b)(?=.*\b(\d+)\b).*/i;
    const yearsBeforeNumber = /(?<!\d\s*)(?:year|years|yr|yrs)\b/i;
    const isK12 = !!(kAndYear.test(scrubbedAgeDesc) || kAndNumber.test(scrubbedAgeDesc) || yearsBeforeNumber.test(scrubbedAgeDesc));
    const adultPattern = /\b(adult.+)\b|\b(mature.+)\b/i;
    const isAdult = adultPattern.test(scrubbedAgeDesc);
    const infantPattern = /\b(infant.+)\b/i;
    const isInfant = infantPattern.test(scrubbedAgeDesc);
    const openPattern = /\b(open.*|any.*age.*|all.*age.*)\b/i;
    const isOpen = openPattern.test(scrubbedAgeDesc);
    const seniorPattern = /\b(senior.*)\b/i;
    const isSenior = seniorPattern.test(scrubbedAgeDesc);
    const juniorPattern = /\b(junior.*)\b/i;
    const isJunior = juniorPattern.test(scrubbedAgeDesc);
    const preProPattern = /\b(pre.*pro.*)\b/i;
    const isPrePro = preProPattern.test(scrubbedAgeDesc);
    if (isAdult) {
      category._ageType = 'Adult';
      category._fromAge = `${MaxAgeValue}+`;
      category._toAge = `${MaxAgeValue}+`;
      category._compRangeDesc = 'Adult';
    } else if (isOpen) {
      category._ageType = 'Open';
      StandardiseAges(category);
    } else if (isSenior) {
      category._ageType = 'Senior';
      StandardiseAges(category);
    } else if (isJunior) {
      category._ageType = 'Junior';
      StandardiseAges(category);
    } else if (isPrePro) {
      category._ageType = 'Pre-pro';
      StandardiseAges(category);
    } else if (isInfant) {
      category._ageType = 'Infant';
      category._fromAge = MinAgeValue;
      category._toAge = MinAgeValue;
      category._fromYear = 'Infant';
      category._toYear = 'Infant';
      category._compRangeDesc = 'Infant';
    } else if (isK12) {
      category._ageType = 'School Year';
      StandardiseSchoolYears(category);
    } else {
      category._ageType = 'Age';
      StandardiseAges(category);
    }
    // update age ranges
    UpdateAgeRangeArrays(category);
  } catch (err) {
    console.error(`❌ StandardiseAgeDetails - ageDesc=${category?.ageDesc}, error=`, err);
  }
};
export const UpdateAgeRangeArrays = category => {
  SetAgesArray(category);
  SetSchoolYearsArray(category);
  SetCompRangeDesc(category);
};
/**
 * Standardise based on age
 */
export const StandardiseAges = category => {
  try {
    const ageDesc = ScrubAgeDesc(category.ageDesc ?? '');
    const scrubbedAge = checkAge(ageDesc);
    const numberPattern = /\d+/g;
    const numberParts = scrubbedAge.match(numberPattern);
    const containsOver = containsOverPattern(scrubbedAge);
    const containsUnder = containsUnderPattern(scrubbedAge);
    if (numberParts && numberParts.length === 1) {
      const ageNumber = Number(numberParts[0]);
      const age = GetAgeFromNumber(ageNumber) ?? MaxAgeValue;
      if (containsOver) {
        if (ageNumber >= MaxAgeNumber) {
          category._ageDirective = 'max';
          category._fromAge = `${String(ageNumber)}+`;
          category._toAge = `${String(ageNumber)}+`;
        } else {
          category._ageDirective = 'over';
          category._fromAge = age;
          category._toAge = '';
        }
      } else if (containsUnder) {
        if (ageNumber === MinAgeNumber) {
          category._ageDirective = 'min';
          category._fromAge = MinAgeValue;
          category._toAge = MinAgeValue;
        } else if (ageNumber > MaxAgeNumber) {
          category._ageDirective = 'max';
          category._fromAge = `${MaxAgeValue}`;
          category._toAge = `${String(ageNumber)}`;
        } else {
          category._ageDirective = 'under';
          category._fromAge = '';
          category._toAge = age;
        }
      } else {
        category._fromAge = age;
        category._toAge = age;
      }
    } else if (numberParts && numberParts.length >= 2) {
      category._ageDirective = 'both';
      const fromAgeNumber = GetNumberFromAge(numberParts[0]) > 0 ? GetNumberFromAge(numberParts[0]) : MinAgeNumber;
      const toAgeNumber = GetNumberFromAge(numberParts[1]) > 0 ? GetNumberFromAge(numberParts[1]) : MaxAgeNumber;
      category._fromAge = `${String(fromAgeNumber)}`;
      category._toAge = `${String(toAgeNumber)}`;
    }
    /**
     * Set range description
     */
    SetCompRangeDesc(category);
  } catch (err) {
    console.error(`❌ StandardiseAges - category=`, category, err);
  }
};
/**
 * Standardise based on a k-12 value
 */
export const StandardiseSchoolYears = category => {
  try {
    const ageDesc = ScrubAgeDesc(category.ageDesc ?? '');
    const scrubbedAge = checkAge(ageDesc);
    const yearPattern = /\d+|k/gi;
    const yearParts = ageDesc.match(yearPattern);
    if (!yearParts) {
      category._compRangeDesc = `Unable to determine`;
      return;
    }
    const containsOver = containsOverPattern(scrubbedAge);
    const containsUnder = containsUnderPattern(scrubbedAge);
    if (yearParts.length > 1) {
      category._fromYear = String(yearParts[0]).toUpperCase();
      category._toYear = String(yearParts[1]).toUpperCase();
    } else if (yearParts.length === 1) {
      const theYear = yearParts[0];
      if (containsOver) {
        category._ageDirective = 'over';
        category._fromYear = theYear.toUpperCase();
        category._toYear = '';
      } else if (containsUnder) {
        category._ageDirective = 'under';
        category._fromYear = '';
        category._toYear = theYear.toUpperCase();
      } else {
        category._fromYear = theYear.toUpperCase();
        category._toYear = theYear.toUpperCase();
      }
    }
    // default
    if (category._fromYear && !category._toYear) category._toYear = MaxSchoolYear;
    if (!category._fromYear && category._toYear) category._fromYear = MinSchoolYear;
    // year descriptions
    SetCompRangeDesc(category);
  } catch (err) {
    console.error(`❌ StandardiseSchoolYears - category=`, category, err);
  }
};
export const SetCompRangeDesc = category => {
  if (isAgeType(category._ageType)) {
    if (!category._fromAge && !category._toAge) {
      if (category._ageType === 'Age') {
        category._compRangeDesc = `Not specified`;
      } else {
        category._compRangeDesc = category._ageType;
      }
    } else if (category._fromAge === category._toAge) {
      category._compRangeDesc = `${category._fromAge} years old`;
    } else {
      category._compRangeDesc = `${category._fromAge} to ${category._toAge} years old`;
    }
  } else if (category._ageType === 'School Year') {
    // year descriptions
    if (!category._fromYear && !category._toYear) {
      category._compRangeDesc = `Unable to determine`;
    } else if (category._fromYear === category._toYear) {
      category._compRangeDesc = `School Year ${category._fromYear}`;
    } else {
      category._compRangeDesc = `School Years ${category._fromYear} thru ${category._toYear}`;
    }
  } else if (category._ageType === 'Adult') {
    category._compRangeDesc = 'Adult';
  } else if (category._ageType === 'Infant') {
    category._compRangeDesc = 'Infant';
  } else {
    category._compRangeDesc = `${category._ageType || 'Open'}`;
  }
};
/**
 * build _ages array
 */
export const SetAgesArray = category => {
  category._ages = [];
  if (!isAgeType(category._ageType)) return;
  if (!category._fromAge && !category._toAge) return;
  // same from and to
  if (category._fromAge === category._toAge) {
    category._ages.push(category._fromAge);
    return;
  }
  const {
    FromNumber,
    ToNumber
  } = GetNumbersForAgesOrSchoolYears(category);
  const fromAge = FromNumber;
  const toAge = ToNumber;
  const ages = [];
  if (Number.isNaN(fromAge) && Number.isNaN(toAge)) {
    return;
  } else if (Number.isNaN(fromAge)) {
    category._ages.push(category._toAge);
    return;
  } else if (Number.isNaN(toAge)) {
    category._ages.push(category._fromAge);
    return;
  }
  if (fromAge > MaxAgeNumber || toAge > MaxAgeNumber) {
    if (category._fromAge) category._ages.push(category._fromAge);
    if (category._fromAge !== category._toAge && category._toAge) category._ages.push(category._toAge);
    return;
  }
  const fromStart = Math.max(fromAge, 0);
  const toEnd = !toAge ? MaxAgeNumber : Math.min(MaxAgeNumber, toAge);
  addNumberRanges(fromStart, toEnd, ages);
  // convert ages to ages
  const ageSet = new Set();
  if (toAge > MaxAgeNumber) ageSet.add('18+');
  for (const age of ages) {
    const entry = Object.entries(AgeValues).find(item => item[1] === age);
    if (entry) {
      ageSet.add(entry[0]);
    }
  }
  category._ages = Array.from(ageSet);
};
/**
 * build _schoolYears array
 */
export const SetSchoolYearsArray = category => {
  category._schoolYears = [];
  if (category._ageType !== 'School Year') return;
  if (!category._fromYear && !category._toYear) return;
  const {
    FromNumber,
    ToNumber
  } = GetNumbersForAgesOrSchoolYears(category);
  const fromYear = FromNumber;
  const toYear = ToNumber;
  const years = [];
  // same from and to
  if (category._fromYear === category._toYear && category._fromYear) {
    category._schoolYears.push(category._fromYear);
    return;
  }
  const fromStart = Math.max(fromYear, 0);
  const toEnd = !toYear ? MaxSchoolYearNumber : Math.min(MaxSchoolYearNumber, toYear);
  addNumberRanges(fromStart, toEnd, years);
  // convert years to schoolYears
  const yearSet = new Set();
  for (const year of years) {
    const entry = Object.entries(KThru12Years).find(item => item[1] === year);
    if (entry) {
      yearSet.add(entry[0]);
    }
  }
  category._schoolYears = Array.from(yearSet);
};
/**
 * Adjust AGE details for unders
 */
const getCategoryKey = category => {
  return `${category._compStyle}~${category._compSoloOrGroup}~${category._ageType}`;
};
/**
 * Updates ages and mutates the array
 */
export const AdjustCategoryAges = categories => {
  // const savedValues = JSON.stringify(categories);
  const grouped = [];
  for (const cat of categories) {
    const key = getCategoryKey(cat);
    const {
      FromNumber,
      ToNumber
    } = GetNumbersForAgesOrSchoolYears(cat);
    const groupFromNumber = cat._ageDirective === 'under' ? 0 : FromNumber;
    const groupToNumber = cat._ageDirective === 'over' ? 0 : ToNumber;
    // do not process if greater than max age
    if (groupFromNumber > MaxAgeNumber || groupToNumber > MaxAgeNumber) continue;
    const index = grouped.findIndex(item => item.catKey === key && item.fromNumber === groupFromNumber && item.toNumber === groupToNumber);
    if (index === -1) grouped.push({
      catKey: key,
      ageType: cat._ageType,
      fromNumber: groupFromNumber,
      toNumber: groupToNumber,
      newFromNumber: 0,
      newToNumber: 0
    });
  }
  // sort groups - ascending by AGE/SchoolYear and update lastToAge or Year
  grouped.sort((a, b) => {
    if (a.catKey !== b.catKey) return a.catKey > b.catKey ? 1 : -1;
    const aSort = `${SortableNumber(a.fromNumber)}${SortableNumber(a.toNumber)}`;
    const bSort = `${SortableNumber(b.fromNumber)}${SortableNumber(b.toNumber)}`;
    return aSort > bSort ? 1 : -1;
  });
  let lastAgeKey = '';
  let lastYearCatKey = '';
  let lastToAgeNumber = 0;
  let lastToYearNumber = 0;
  const lastIndex = grouped.length - 1;
  /**
   * Age groupings
   */
  for (let i = 0; i < grouped.length; i++) {
    const group = grouped[i];
    if (isAgeType(group.ageType)) {
      // new key
      if (lastAgeKey !== group.catKey) {
        lastAgeKey = group.catKey;
        lastToAgeNumber = 0;
      }
      // from is zero ... need to calculate as 1 more than prior to
      if (group.fromNumber <= 0) {
        const nextFrom = lastToAgeNumber ? lastToAgeNumber + 1 : MinAgeNumber;
        group.newFromNumber = nextFrom <= group.toNumber ? nextFrom : group.toNumber;
      } else {
        group.newFromNumber = group.fromNumber;
      }
      // to is zero ... need to compute next to number
      if (group.toNumber <= 0) {
        let nextGroup = i < lastIndex ? grouped[i + 1] : {
          catKey: group.catKey,
          ageType: group.ageType,
          fromNumber: MaxAgeNumber + 1,
          toNumber: MaxAgeNumber + 1
        };
        if (nextGroup.catKey !== group.catKey || nextGroup.ageType !== group.ageType) {
          nextGroup = {
            catKey: group.catKey,
            ageType: group.ageType,
            fromNumber: MaxAgeNumber + 1,
            toNumber: MaxAgeNumber + 1
          };
        }
        const nextTo = nextGroup.fromNumber > 0 ? nextGroup.fromNumber - 1 : nextGroup.toNumber - 1;
        group.newToNumber = nextTo >= group.fromNumber ? nextTo : group.fromNumber;
      } else {
        group.newToNumber = group.toNumber;
      }
      lastToAgeNumber = group.newToNumber;
    }
    /**
     * School year groupings
     */
    if (group.ageType === 'School Year') {
      if (lastYearCatKey !== group.catKey) {
        lastYearCatKey = group.catKey;
        lastToYearNumber = group.toNumber;
      }
      // from is zero ... need to calculate as 1 more than prior to
      if (group.fromNumber === 0) {
        const nextFrom = lastToYearNumber ? lastToYearNumber + 1 : 1;
        group.newFromNumber = nextFrom <= group.toNumber ? nextFrom : group.toNumber;
      } else {
        group.newFromNumber = group.fromNumber;
      }
      // to is zero ... need to compute next to number
      if (group.toNumber === 0) {
        let nextGroup = i < lastIndex ? grouped[i + 1] : {
          catKey: group.catKey,
          ageType: group.ageType,
          fromNumber: MaxSchoolYearNumber + 1,
          toNumber: MaxSchoolYearNumber + 1
        };
        if (nextGroup.catKey !== group.catKey || nextGroup.ageType !== group.ageType) {
          nextGroup = {
            catKey: group.catKey,
            ageType: group.ageType,
            fromNumber: MaxSchoolYearNumber + 1,
            toNumber: MaxSchoolYearNumber + 1
          };
        }
        const nextTo = nextGroup.fromNumber > 0 ? nextGroup.fromNumber - 1 : nextGroup.toNumber - 1;
        group.newToNumber = nextTo >= group.fromNumber ? nextTo : group.fromNumber;
      } else {
        group.newToNumber = group.toNumber;
      }
      lastToYearNumber = group.newToNumber;
    }
  }
  /**
   * Update from age/schoolyear
   */
  for (const category of categories) {
    const key = getCategoryKey(category);
    const {
      FromNumber,
      ToNumber
    } = GetNumbersForAgesOrSchoolYears(category);
    const groupFromNumber = FromNumber;
    const groupToNumber = ToNumber;
    const group = grouped.find(item => item.catKey === key && item.fromNumber === groupFromNumber && item.toNumber === groupToNumber);
    if (!group) continue;
    // check if change - update the from Age/SchoolYear with the new limit
    if (isAgeType(category._ageType)) {
      category._fromAge = GetAgeFromNumber(group.newFromNumber) ?? category._fromAge;
      category._toAge = GetAgeFromNumber(group.newToNumber) ?? category._toAge;
    } else if (category._ageType === 'School Year') {
      category._fromYear = GetSchoolYearFromNumber(group.newFromNumber) ?? category._fromYear;
      category._toYear = GetSchoolYearFromNumber(group.newToNumber) ?? category._toYear;
    }
  }
  // update AgeRanges
  for (const category of categories) {
    UpdateAgeRangeArrays(category);
  }
  // const updatedValues = JSON.stringify(categories);
  // if (savedValues !== updatedValues) {
  //   // nothing to see here
  // }
}; // end AdjustCategoryAges
export const GetNumbersForAgesOrSchoolYears = category => {
  if (isAgeType(category._ageType)) {
    const fromNumber = GetNumberFromAge(category._fromAge);
    const toNumber = GetNumberFromAge(category._toAge);
    return {
      FromNumber: fromNumber,
      ToNumber: toNumber
    };
  } else if (category._ageType === 'School Year') {
    const fromNumber = GetNumberFromSchoolYear(category._fromYear);
    const toNumber = GetNumberFromSchoolYear(category._toYear);
    return {
      FromNumber: fromNumber,
      ToNumber: toNumber
    };
  } else {
    return {
      FromNumber: 0,
      ToNumber: 0
    };
  }
};

import { gql } from 'apollo-angular';

import { CompetitionCreateRecord, CompetitionMutateRecord } from '@danceShared/competitions-shared/competition.model';
import { FT_propsRecord } from '@ft/lib/crud-helper-lib';
import { Category } from '@furnas-technology/common-library/categories';

export const competitionQueryFields = [
	'competitionName',
	'competitionLogo',
	'venueName',
	'venueLocation',
	'links { desc, url }',
	'competitionFees',
	'feesNotes',
	'datesStatus',
	'entriesOpen { datepart, notepart }',
	'entriesClose { datepart, notepart }',
	'entriesLateClose { datepart, notepart }',
	'competitionDates { fromDate, toDate, notes }',
	'musicSubmission  { datepart, notepart }',
	'prizes',
	'ageCutoff',
	'ageCutoffNote',
	'additionalInfo',
	'_geoTag { formattedAddress, location { type coordinates} , subpremise, streetnumber, streetname, city, county, state, statecode, country, countrycode, postcode }',
	'_addressDisplay',
	'syllabus_ids',
	'_syllabus',

	'organisation_id',
	'_organisation',
	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const competitionSyllabusQueryFields = [
	'syllabus_ids',
	'_syllabus',
	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const competitionListFields = [
	'competitionName',
	'competitionLogo',
	'venueName',
	'venueLocation',
	'datesStatus',
	'entriesOpen { datepart, notepart }',
	'entriesClose { datepart, notepart }',
	'entriesLateClose { datepart, notepart }',
	'competitionDates { fromDate, toDate, notes }',
	'musicSubmission { datepart, notepart }',
	'_addressDisplay',

	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const unsubscribedQueryFields = [
	'competitionName',
	'venueName',
	'venueLocation',
	'competitionLogo',
	'_hasOrganisation',
	'_addressDisplay',
	'_geoTag { formattedAddress, location { type coordinates} , subpremise, streetnumber, streetname, city, county, state, statecode, country, countrycode, postcode }',

	'organisation_id',
	'_organisation',

	'datesStatus',
	'entriesOpen { datepart, notepart }',
	'entriesClose { datepart, notepart }',
	'entriesLateClose { datepart, notepart }',
	'competitionDates { fromDate, toDate, notes }',

	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const bookingsQueryFields = [
	'competitionName',
	'venueName',
	'venueLocation',
	'competitionLogo',
	'_addressDisplay',
	'_geoTag { formattedAddress, location { type coordinates} , subpremise, streetnumber, streetname, city, county, state, statecode, country, countrycode, postcode }',

	'competitionDates { fromDate, toDate, notes }',
	'musicSubmission { datepart, notepart }',
	'datesStatus',
	'entriesOpen { datepart, notepart }',
	'entriesClose { datepart, notepart }',
	'entriesLateClose { datepart, notepart }',

	'organisation_id',
	'_organisation',
	'_hasOrganisation',

	'syllabus_ids',
	'_syllabus',

	'createdAt',
	'updatedAt',
	'deleted',
	'retrievedAt',
];

export const ApiUrls = {
	competitions: {
		totals: () => gql`
      query {
        totals: competitionTotals
      }
    `,

		getCompetitionsCount: () => gql`
      query ($countFilter: FilterCountCompetitionInput) {
        competitionCount(filter: $countFilter)
      }
    `,

		getCompetitions: () => gql`
      query($filter: FilterFindManyCompetitionInput, $sort: SortFindManyCompetitionInput, $skip: Int, $limit: Int) {
        competitionMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
          _id
          ${competitionQueryFields.join(`\r\n`)}
        }
      }
    `,

		getUnsubscribedCompetitions: () => gql`
    query($filter: FilterFindManyCompetitionInput, $sort: SortFindManyCompetitionInput, $skip: Int, $limit: Int) {
      competitionMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
        _id
        ${unsubscribedQueryFields.join(`\r\n`)}
      }
    }
  `,

		getBookingsCompetitions: () => gql`
  query($filter: FilterFindManyCompetitionInput, $sort: SortFindManyCompetitionInput, $skip: Int, $limit: Int) {
    competitionMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
      _id
      ${bookingsQueryFields.join(`\r\n`)}
    }
  }
`,

		getCompetitionsList: () => gql`
    query($filter: FilterFindManyCompetitionInput, $sort: SortFindManyCompetitionInput, $skip: Int, $limit: Int) {
      competitionMany(filter: $filter, sort: $sort, skip: $skip, limit: $limit) {
        _id
        ${competitionListFields.join(`\r\n`)}
      }
    }
  `,

		competitionCreateOne: (record: CompetitionCreateRecord) => {
			const mutateRecord = FT_propsRecord(record);

			return {
				mutation: gql`
          mutation ($record: CreateOneCompetitionInput!) {
            createResult: competitionCreateOne(record: $record) {
              recordId
              record {
                _id
                ${competitionQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { record: mutateRecord },
			};
		},

		competitionUpdateById: (_id: string, record: CompetitionMutateRecord) => {
			const mutateRecord = FT_propsRecord(record);
			console.debug(`competitionUpdateById - mutateRecord keys=${Object.keys(mutateRecord).join('\n')}`);

			return {
				mutation: gql`
          mutation ($_id: MongoID!, $record: UpdateByIdCompetitionInput!) {
            mutateResult: competitionUpdateById(_id: $_id, record: $record) {
              recordId
              record {
                _id
                ${competitionQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { _id: _id, record: mutateRecord },
			};
		},

		competitionDeleteById: (_id: string) => {
			return {
				mutation: gql`
          mutation ($input: DocumentIdInputInput!) {
            deleteResult: competitionDeleteById(input: $input)
          }
        `,
				variables: { input: { documentId: `${_id}` } },
			};
		},

		setCategories: (_id: string, record: Category[]) => {
			return {
				mutation: gql`
          mutation ($_id: MongoID!, $record: [JSON]) {
            mutateResult: competitionUpdateById(_id: $_id, record: { categories: $record }) {
              recordId
              record {
                _id
                ${competitionQueryFields.join(`\r\n`)}
              }
            }
          }
        `,
				variables: { _id: _id, record: record },
			};
		},
	},
};

import {} from '@angular/core';
import { GetPagedDataRequest } from '@ft/lib/crud-helper-lib';
import { StoreDefinition } from '@ft/lib/indexeddb-lib';
import { SignalStoreType, createSignalStore } from '@ft/lib/signal-store-lib';
import { Competition } from '@furnas-technology/common-library/competitions';
import { CompetitionSearchBarFields } from '../competition.model';
import { ApiUrls } from '../data/api.constants';
import { getManyProperty, nameProperty, sortProperty } from '../data/competition.constants';

const getPagedDataRequest: GetPagedDataRequest<Competition> = {
	getManyProperty: getManyProperty,
	getManyApi: ApiUrls.competitions.getUnsubscribedCompetitions(),
	chunkSize: 150,
};

const sortFunction = (a: Competition, b: Competition): number => {
	return (a.competitionName ?? '').toLocaleLowerCase().localeCompare((b.competitionName ?? '').toLocaleLowerCase());
};

const selectById = (competition: Competition) => competition._id;
/**
 * Indexed DB defintions
 */
const UNSUBSCRIBED_COMPETITIONS: StoreDefinition = {
	tableName: `Competitions`,
	keyPath: '_id',
	autoIncrement: false,
	indexFields: `_id, updatedAt, competitionName, venueLocation, venueName, retrievedAt `,
};

/**
 * Create signal store
 */
export const UnsubscribedCompetitionStore: SignalStoreType<Competition> = createSignalStore<Competition>({
	signalStoreName: 'UnsubscribedCompetitionStore',
	getPagedDataRequest: getPagedDataRequest,
	createRowRequest: undefined,
	mutateRowRequest: undefined,
	apiDelete: undefined,
	editComponent: undefined,
	editResultProperty: undefined,
	nameProperty: nameProperty,
	sortProperty: sortProperty,
	searchBarFields: CompetitionSearchBarFields,
	loadOn: 'auth',
	classConstructor: Competition,

	indexedDBConfig: {
		indexedDBName: 'unsubscribed-competitions',
		indexedDBVersion: 2.1,
		indexedDBStoreList: [UNSUBSCRIBED_COMPETITIONS],
	},
});

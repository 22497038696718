import { Injectable, computed, inject } from '@angular/core';

import { of, take, tap } from 'rxjs';

import { PromptService, SelectResult, SelectThenPromptInput } from '@ft/lib/dialogs-lib';
import { GroupByProperty } from '@ft/lib/facade-lib';
import { GenericStoreService, GenericStoreServiceInput } from '@ft/lib/store-service-lib';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { deepComputed } from '@ngrx/signals';
import { SyllabusCardComponent } from '../components/syllabus-card/syllabus-card.component';
import { SyllabusStore } from '../store/syllabus.store';
import { Syllabus } from '../syllabus.model';
import { nameProperty, resultProperty, signalStoreName } from './syllabus.constants';

const genericStoreServiceInput: GenericStoreServiceInput<Syllabus> = {
	signalStoreName: signalStoreName,
	signalStore: SyllabusStore,

	viewComponent: SyllabusCardComponent,
	editComponent: SyllabusCardComponent,
	resultProperty: resultProperty,
	nameProperty: nameProperty,
};

@Injectable({
	providedIn: 'root',
})
export class SyllabusService extends GenericStoreService<Syllabus> {
	readonly store = inject(SyllabusStore);
	private prompt = inject(PromptService);

	/**
	 * store selector
	 */
	public syllabusStore = computed(() => {
		return this.store;
	});

	constructor() {
		super(genericStoreServiceInput);
		console.debug(`${this.constructor.name} - constructor - ${genericStoreServiceInput.signalStoreName}`);
	}

	/**
	 *  virtual fields
	 */
	searchSyllabuses = computed(() =>
		this.syllabusStore()
			.filteredDocuments()
			.map((x) => x.record),
	);

	filteredSyllabusNames = computed(() => {
		const rows = this.afs.excludeFieldsFilter(this.searchSyllabuses(), ['syllabusName'], 'notOmitted');
		const values = GroupByProperty(rows, 'syllabusName');
		return Array.from(values)
			.filter((x) => !!String(x).trim())
			.sort();
	});

	syllabusNames = computed<string[]>(() => {
		return this.documents().map((x) => x.syllabusName);
	});

	selectSyllabusByName(name: string): Syllabus | undefined {
		return this.documents().find((item) => item.syllabusName === name);
	}

	selectedSyllabus = computed(() => this.syllabusStore().selectedDocument() ?? new Syllabus());

	selectedSyllabusDetails = deepComputed(() => ({
		syllabusName: this.selectedSyllabus().syllabusName,
		categories: this.selectedSyllabus().categories,
		_styleGroups: this.selectedSyllabus()._styleGroups,
		_displayStyles: this.selectedSyllabus()._displayStyles,
		_compStyles: this.selectedSyllabus()._compStyles,
		_categoryTypes: this.selectedSyllabus()._categoryTypes,
		_baseCompStyles: this.selectedSyllabus()._baseCompStyles,
		_compSoloOrGroups: this.selectedSyllabus()._compSoloOrGroups,
		_compRangeDescs: this.selectedSyllabus()._compRangeDescs,
		_performanceTimes: this.selectedSyllabus()._performanceTimes,
		_sections: this.selectedSyllabus()._sections,
		_divisions: this.selectedSyllabus()._divisions,
		_competition_ids: this.selectedSyllabus()._competition_ids,
		_hasSections: this.selectedSyllabus()._hasSections,
		_ages: this.selectedSyllabus()._ages,
		_schoolYears: this.selectedSyllabus()._schoolYears,
		_ageTypes: this.selectedSyllabus()._ageTypes,
		sortedStyleGroups: this.selectedSyllabus().sortedStyleGroups,
		sortedCategories: this.selectedSyllabus().sortedCategories,
	}));

	selectSyllabusByIds(ids: string | string[]): Syllabus[] {
		const idArray = Array.isArray(ids) ? ids : [ids];
		return this.syllabusStore()
			.documents()
			.filter((item) => idArray.includes(item._id));
	}

	promptAndEditSyllabus(syllabus_ids: string[]): void {
		try {
			if (!syllabus_ids.length) return;

			// if single syllabus, edit single syllabus
			if (syllabus_ids.length === 1) {
				this.editDocument({ id: syllabus_ids[0], modeType: 'Edit' });
			} else if (syllabus_ids.length > 1) {
				const selectThenPromptInput: SelectThenPromptInput<Syllabus> = {
					id: syllabus_ids,
					promptProperty: 'syllabusName',
					keyProperty: '_id',
					title: `Select Syllabus to Edit`,
					message: `Select syllabus`,
					selectFunction$: (ids) => of(this.selectSyllabusByIds(ids)),
				};

				this.prompt
					.selectThenPrompt(selectThenPromptInput)
					.pipe(
						take(1),
						tap((selectResult: SelectResult) => {
							if (selectResult && selectResult.status === 'select' && selectResult.selectedKey) {
								this.editDocument({ id: selectResult.selectedKey as string, modeType: 'Edit' });
							}
						}),
					)
					.subscribe();
			} else {
				this.editDocument({ id: '', modeType: 'Add New' });
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `editSyllabus`);
		}
	}
} // end class

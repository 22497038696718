export const Sections = ['Open', 'Elite', 'Intermediate', 'My First Year En Pointe', 'My First Year On Stage', 'Novice', 'Open Assist', 'Restricted', 'Special Restricted'];
export const StandardiseSection = i_section => {
  let stdSection = undefined;
  if (!i_section) return stdSection;
  const section = String(i_section).trim();
  if (section.match(/^special/i)) {
    stdSection = 'Special Restricted';
  } else if (section.match(/^restricted/i)) {
    stdSection = 'Restricted';
  } else if (section.match(/^novice/i)) {
    stdSection = 'Novice';
  } else if (section.match(/^open/i)) {
    stdSection = 'Open';
  } else if (section.match(/^elite/i)) {
    stdSection = 'Elite';
  } else if (section.match(/^inter/i)) {
    stdSection = 'Intermediate';
  } else if (section.match(/^my/i) && section.match(/pointe/i)) {
    stdSection = 'My First Year En Pointe';
  } else if (section.match(/^my/i) && section.match(/stage/i)) {
    stdSection = 'My First Year On Stage';
  } else {
    stdSection = undefined;
  }
  return stdSection;
};

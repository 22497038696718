<div class="syllabus-groups-component" [ngClass]="layout.cssClasses()">
  @if (styleGroups().length) {
    @if (!layout.isLT_medium()) {
      <div class="category-list category-header" #categorylist aria-label="styleGroup.style.header">
        <h4 class="style">Style</h4>

        <div class="solo-or-group" aria-label="roup.soloOrGroup.header">
          <h4>Solo/Group</h4>
        </div>

        <div class="age-desc" aria-label="styleGroup.ageDesc.header">
          <h4>Age</h4>
        </div>

        <h4 class="performance-time" aria-label="styleGroup.performanceTime.header">Time</h4>

        <h4 class="sections" aria-label="styleGroup.sections.header">Sections</h4>
      </div>
    }

    @for (styleGroup of styleGroups(); track $index) {
      <div class="category-list" #categorylist aria-label="styleGroup.style">
        <div class="style" #style>{{ styleGroup.displayStyle }}</div>

        <div class="solo-or-group" aria-label="roup.soloOrGroup">
          @for (value of styleGroup.soloOrGroup; track value) {
            <p class="bullet-point-primary">{{ value }}</p>
          }
        </div>

        <div class="age-desc" aria-label="styleGroup.ageDesc">
          @if (styleGroup.rangeDesc.length) {
            <p style="font-size: smaller">{{ styleGroup.rangeDesc.join(', ') }}</p>
          }
        </div>

        <p class="pre-line performance-time" style="font-size: smaller" aria-label="styleGroup.performanceTime">
          {{ styleGroup.performanceTime.join(', ') }}
        </p>

        <p class="pre-line sections" style="font-size: small" aria-label="styleGroup.sections">
          {{ styleGroup.sections.join(sectionJoin()) }}
        </p>
      </div>
    }
  }
</div>

import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlySelectOption } from '@ngx-formly/core/select';

export const CompetitionEditCardFields = (ageCutoffs: FormlySelectOption[]): FormlyFieldConfig[] => [
	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			// nothing to see here
		},
		fieldGroup: [
			{
				className: 'flex-0',
				type: 'image-upload',
				key: 'competitionLogo',
				props: {
					label: 'Logo',
					placeholder: 'Comp\r\nLogo',
					readonly: false,
					height: '56px',
					width: '64px',
				},
				expressions: {
					// 'props.disabled': '!model.competition.competitionName',
				},
			},
			{
				className: 'flex-1',
				type: 'input',
				key: 'competitionName',
				props: {
					label: 'Competition Name',
					placeholder: 'Enter the competition name',
					readonly: false,
					required: true,
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			'props.disabled': '!model.competitionName',
			hide: '!model.competitionName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'input',
				key: 'venueName',
				props: {
					label: 'Venue Name',
					placeholder: 'Enter the venue name',
					readonly: false,
				},
			},
			{
				className: 'flex-2',
				type: 'input',
				key: 'venueLocation',
				props: {
					label: 'Venue Location',
					placeholder: 'Enter the venue street address',
					readonly: false,
				},
				expressions: {
					// 'props.disabled': '!model.competition.competitionName',
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			hide: '!model.competitionName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'output',
				key: '_competitionDatesMinMaxDisplay',
				props: {
					label: 'Competition Dates',
					readonly: true,
				},
			},
			{
				className: 'flex-1',
				type: 'output',
				key: '_entriesOpenMin.datepart',
				props: {
					label: 'Entries Open',
					readonly: true,
					type: 'date',
					placeholder: 'TBD',
				},
			},
			{
				className: 'flex-1',
				type: 'output',
				key: '_entriesCloseMax.datepart',
				props: {
					label: 'Entries Close',
					readonly: true,
					type: 'date',
				},
			},
			{
				className: 'flex-1',
				type: 'output',
				key: '_entriesLateCloseMax.datepart',
				props: {
					label: 'Entries Late Close',
					readonly: true,
					type: 'date',
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row align-center',
		fieldGroup: [
			{
				className: 'flex-1 no-wrap',
				type: 'syllabus-options',
				key: 'syllabus_ids',
				props: {
					label: 'Syllabus:',
					flex: true,
					valueClass: 'strong',
					required: false,
					hideRequiredMarker: false,
					placeholder: 'Select syllabus',
					createOption: true,
				},
				expressions: {
					// 'props.readonly': 'formState.addNew ? false : true',
				},
			},
		],
	},

	{
		fieldGroupClassName: 'display-flex-row align-center',
		fieldGroup: [
			{
				className: 'flex-1 no-wrap',
				type: 'organisation-options',
				key: 'organisation_id',
				props: {
					label: 'Organisation:',
					flex: true,
					valueClass: 'strong',
					required: false,
					hideRequiredMarker: false,
					placeholder: 'Select organisation',
					createOption: true,
				},
				expressions: {
					// 'props.readonly': 'formState.addNew ? false : true',
				},
			},
		],
	},

	{
		className: 'flex-1',
		type: 'textarea',
		key: 'additionalInfo',
		props: {
			label: 'Additional Information',
			placeholder: 'Enter additional info',
			readonly: false,
			required: false,
			autosize: true,
			autosizeMinRows: 3,
			autosizeMaxRows: 10,
		},
	},

	{
		key: 'links',
		type: 'repeat',
		props: {
			addText: 'Add new link',
			label: 'Links',
		},
		fieldArray: {
			fieldGroupClassName: 'ft-array-flex-row',
			className: 'ft-array-flex-field',
			validation: {
				show: true,
			},
			fieldGroup: [
				{
					type: 'input',
					key: 'desc',
					props: { required: false, label: 'Description', readonly: false },
					className: 'ft-array-field flex-1',
				},
				{
					type: 'input',
					key: 'url',
					props: { required: false, label: 'Link address', readonly: false },
					className: 'ft-array-field flex-3',
				},
			],
		},
	},

	{
		key: 'competitionDates',
		type: 'repeat',
		props: {
			addText: 'Add new competition date',
			label: 'Competition Dates',
		},
		fieldArray: {
			fieldGroupClassName: 'ft-array-flex-row',
			className: 'ft-array-flex-field',
			validation: {
				show: true,
			},
			fieldGroup: [
				{
					type: 'datepicker',
					key: 'fromDate',
					props: {
						required: false,
						label: 'From Date',
						readonly: false,
						// datepickerOptions: {
						//   dateFormat: 'D',
						//   startView: 'month',
						//   datepickerTogglePosition: 'suffix',
						//   disabled: false,
						//   opened: false,
						// },
					},
					className: 'ft-array-field flex-1',
				},
				{
					type: 'datepicker',
					key: 'toDate',
					props: { required: false, label: 'To Date', readonly: false },
					className: 'ft-array-field flex-1',
				},
				{
					type: 'input',
					key: 'notes',
					props: { required: false, label: 'Notes', readonly: false },
					className: 'ft-array-field flex-3',
				},
			],
		},
		validators: {
			validation: [FromAndToDateValidator],
		},
	},

	{
		key: 'competitionFees',
		type: 'repeat',
		props: {
			addText: 'Add new fee',
			label: 'Fee',
		},
		validation: {
			show: true,
		},
		fieldArray: {
			type: 'input',
			props: {
				placeholder: 'Competition fee',
				required: true,
			},
			className: 'ft-array-field',
			validation: { show: true },
		},
	},

	{
		key: 'feesNotes',
		type: 'repeat',
		props: {
			addText: 'Add new fee note',
			label: 'Fee Notes',
		},
		validation: {
			show: true,
		},
		fieldArray: {
			type: 'input',
			props: {
				placeholder: 'Fee note',
				required: true,
			},
			className: 'ft-array-field',
			validation: {
				show: true,
			},
		},
	},

	{
		fieldGroupClassName: 'display-flex-row',
		expressions: {
			// 'props.disabled': '!model.competitionName',
			// hide: '!model.competitionName',
		},
		fieldGroup: [
			{
				className: 'flex-1',
				type: 'select',
				key: 'ageCutoff',
				props: {
					label: 'Age Cutoff',
					placeholder: 'Enter age cutoff',
					options: ageCutoffs,
					readonly: false,
				},
			},
			{
				className: 'flex-2',
				type: 'input',
				key: 'ageCutoffNote',
				props: {
					label: 'Age Cutoff Note',
					placeholder: 'Enter the age cutoff note',
					readonly: false,
				},
				expressions: {
					// 'props.disabled': 'model.ageCutoff !== "Other"',
					// hide: 'model.ageCutoff !== "Other"',
				},
			},
		],
	},

	{
		key: 'prizes',
		type: 'repeat',
		props: {
			addText: 'Add new prize',
			label: 'Prizes',
		},
		validation: {
			show: true,
		},
		fieldArray: {
			type: 'input',
			props: {
				placeholder: 'Prize',
				required: false,
				readonly: false,
			},
			className: 'ft-array-field',
			validation: {
				show: true,
			},
		},
	},

	{
		key: 'datesStatus',
		type: 'radio',
		props: {
			label: 'Dates Status',
			placeholder: 'Pending or Confirmed?',
			options: [
				{ value: 'Pending', label: 'Pending' },
				{ value: 'Confirmed', label: 'Confirmed' },
			],
		},
		validation: {
			show: true,
		},
		expressions: {
			'props.disabled': '!model.competitionName',
			hide: '!model.competitionName',
		},
	},

	{
		key: 'entriesOpen',
		type: 'repeat',
		props: {
			addText: 'Add new entry open date',
			label: 'Entries Open',
		},
		fieldArray: {
			fieldGroupClassName: 'ft-array-flex-row',
			className: 'ft-array-flex-field',
			validation: {
				show: true,
			},
			fieldGroup: [
				{
					type: 'datepicker',
					key: 'datepart',
					props: { required: false, label: 'Date', readonly: false },
					className: 'ft-array-field',
				},
				{
					type: 'input',
					key: 'notepart',
					props: { required: false, label: 'Notes', readonly: false },
					className: 'ft-array-field',
				},
			],
		},
	},

	{
		key: 'entriesClose',
		type: 'repeat',
		props: {
			addText: 'Add new entry close date',
			label: 'Entries Close',
		},
		fieldArray: {
			fieldGroupClassName: 'ft-array-flex-row',
			className: 'ft-array-flex-field',
			validation: {
				show: true,
			},
			fieldGroup: [
				{
					type: 'datepicker',
					key: 'datepart',
					props: { required: false, label: 'Date', readonly: false },
					className: 'ft-array-field',
				},
				{
					type: 'input',
					key: 'notepart',
					props: { required: false, label: 'Notes', readonly: false },
					className: 'ft-array-field',
				},
			],
		},
	},

	{
		key: 'entriesLateClose',
		type: 'repeat',
		props: {
			addText: 'Add new entry late close date',
			label: 'Entries Late Close',
		},
		fieldArray: {
			fieldGroupClassName: 'ft-array-flex-row',
			className: 'ft-array-flex-field',
			validation: {
				show: true,
			},
			fieldGroup: [
				{
					type: 'datepicker',
					key: 'datepart',
					props: { required: false, label: 'Date', readonly: false },
					className: 'ft-array-field',
				},
				{
					type: 'input',
					key: 'notepart',
					props: { required: false, label: 'Notes', readonly: false },
					className: 'ft-array-field',
				},
			],
		},
	},

	{
		key: 'musicSubmission',
		type: 'repeat',
		props: {
			addText: 'Add new music submission date',
			label: 'Music Submission',
		},
		fieldArray: {
			fieldGroupClassName: 'ft-array-flex-row',
			className: 'ft-array-flex-field',
			validation: {
				show: true,
			},
			fieldGroup: [
				{
					type: 'datepicker',
					key: 'datepart',
					props: { required: false, label: 'Submission Date', readonly: false },
					className: 'ft-array-field',
				},
				{
					type: 'input',
					key: 'notepart',
					props: { required: false, label: 'Notes', readonly: false },
					className: 'ft-array-field',
				},
			],
		},
	},
];

export function FromAndToDateValidator(control: AbstractControl): ValidationErrors {
	return {};
}

import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	type OnInit,
	computed,
	effect,
	inject,
	linkedSignal,
	signal,
	viewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { MatDialogModule } from '@angular/material/dialog';

import { MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';

import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCirclePlus, faClone, faCubesStacked, faTrash } from '@fortawesome/free-solid-svg-icons';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import {} from '@furnas-technology/angular-library';
import { CloseIconButtonComponent, ProgressBarComponent } from '@furnas-technology/angular-library/components';

import { MatInputModule } from '@angular/material/input';
import { CategoryDisplayFn } from '@danceShared/categories-shared/category.model';
import { CompetitionService } from '@danceShared/competitions-shared/data/competition.service';
import { SyllabusService } from '@danceShared/syllabuses-shared/data/syllabus.service';
import { Syllabus } from '@danceShared/syllabuses-shared/syllabus.model';
import { GetMutableObject } from '@ft/lib/core-lib';
import { GetHeightDirective, HeightResults } from '@ft/lib/directives';
import { FT_StandardComponent } from '@ft/lib/standard-types-lib';
import { ChangedTableColumnValue, DisplayTableComponent, TableButtonPressed, TableColumn } from '@ft/lib/tables-lib';
import { ResizeObserverDirective } from '@furnas-technology/angular-library/directives';
import { AdjustCategoryAges, ComputeAgeValues } from '@furnas-technology/common-library/ages';
import {
	Category,
	Sections,
	StandardiseCompSoloOrGroup,
	StandardiseCompStyle,
} from '@furnas-technology/common-library/categories';
import { FT_isEmpty, FT_removeNullArrayEntries, GetAsArray } from '@furnas-technology/common-library/functions';
import { IdToString } from '@furnas-technology/common-library/models';

interface SyllabusDetailsForm {
	syllabusName: FormControl<string>;
}

interface SyllabusForm {
	syllabusDetails: FormGroup<SyllabusDetailsForm>;
}

const HEADING_HEIGHT = 56;
const ROW_HEIGHT = 57;

@Component({
	templateUrl: './syllabus-card.component.html',
	styleUrls: ['./syllabus-card.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [
		CloseIconButtonComponent,
		DragDropModule,
		FontAwesomeModule,
		FormsModule,
		GetHeightDirective,
		MatButtonModule,
		MatDatepickerModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		ProgressBarComponent,
		ReactiveFormsModule,
		DisplayTableComponent,
		ResizeObserverDirective,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyllabusCardComponent extends FT_StandardComponent<Syllabus> implements OnInit, AfterViewInit {
	private cdr = inject(ChangeDetectorRef);
	private syllabusService = inject(SyllabusService);
	private competitionService = inject(CompetitionService);

	matAutocomplete = viewChild<MatAutocomplete>(MatAutocomplete);
	heightDirective = viewChild('heightGetter', { read: GetHeightDirective });

	columns: TableColumn<Category>[] = [];

	rowsShowing = signal(0);
	rowsLoaded = computed(() => this.syllabusService.documentsLoaded());
	rowsLoading = computed(() => this.syllabusService.isLoading());
	visibleRows = computed(() => this.syllabusService.filteredDocumentsNotOmitted());

	syllabusCount = (id: string) => this.competitionService.selectSyllabusCount(id);

	faCubesStacked = faCubesStacked;
	faCirclePlus = faCirclePlus;
	cardHeight = signal<number>(0);
	cardWdith = signal<number>(0);

	syllabusId: string = '';
	referencedCompetitions = computed(() => {
		return this.syllabusId ? this.competitionService.selectSyllabusCount(this.syllabusId) : 0;
	});

	displayedCategories = linkedSignal(() => this.model()?.categories ?? []);

	rowsChanged = signal<number>(0);

	tableHeight = signal<number>(200);
	tableWidth = computed(() => {
		return this.layout.ltMediumScreen() ? '98vw' : `1500px`;
	});

	tableHeightPx = computed(() => {
		const height = this.tableHeight() < this.layout.windowHeight() ? this.tableHeight() : this.layout.windowHeight();
		return `${height}px`;
	});

	panelHeight = computed(() => {
		const height = this.tableHeight() + 190;
		return `${height}px`;
	});

	filteredData: BehaviorSubject<Syllabus[]> = new BehaviorSubject<Syllabus[]>([]);

	lastRow = 0;
	get nextId(): string {
		this.lastRow += 1;
		return String(this.lastRow);
	}

	syllabusFormGroup = computed(() => this.form.get('syllabus'));

	syllabusDetails = computed(() => {
		const result = this.syllabusFormGroup()?.get('syllabusDetails') as FormGroup<SyllabusDetailsForm> | null;
		return result;
	});

	constructor() {
		super();
		this.recordName.set('Syllabus');
		this.dialogRef.updatePosition({ top: '10vh' });

		/**
		 * set own dialog size
		 */
		effect(() => {
			this.dialogRef.updateSize(this.tableWidth(), this.panelHeight());
			console.debug(
				`${this.constructor.name} - EFFECT - tableWH=${this.tableWidth()}, ${this.tableHeight()} - cardHeight=${this.cardHeight()}`,
			);
		});

		/**
		 * load data on input changes
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - configId=${this.configId()}, modeType=${this.modeType()}`);
			const { obj: mutableObject, reference } = GetMutableObject(
				this.modeType(),
				this.getMutableId(),
				Syllabus,
				this.syllabusService.store.selectDocumentById,
			);

			console.debug(`${this.constructor.name} - EFFECT - mutableObject=`, mutableObject);
			this.loadModel(mutableObject, reference);
		});

		/**
		 * Form changes - not using Formly, must manually update model
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - formValueChanges=`, this.formValueChanges());
			const formValueChanges = this.formValueChanges();
			const model = this.model();
			if (!formValueChanges || !model) return;

			// update syllabus name if changed
			if (formValueChanges.syllabusName) {
				model.syllabusName = formValueChanges.syllabusName;
				if (!this.modeType().startsWith('Add')) {
					this.documentTitle.set(formValueChanges.syllabusName ?? '');
				}
			}
		});

		/**
		 * Define table columns
		 */
		this.columns = columnDefinitions.map((column) => {
			if (column.name === '_calculated') {
				column.valueFn = (record: Category) => CategoryDisplayFn(record);
			}

			return column;
		});
	}

	/**
	 * Resize
	 */
	onResize(event: DOMRectReadOnly) {
		this.cardHeight.set(event.height);
		this.cardWdith.set(event.width);
	}

	/**
	 * height of rows changed
	 */
	onHeightResults(event: HeightResults) {
		console.debug(`${this.constructor.name} - onHeightResults - totalHeight=${event.totalHeight}`);
		if (event.totalHeight > 0) this.tableHeight.set(event.totalHeight);
	}

	/**
	 * Initialise form
	 */
	override customOnInit(): void {
		this.form.addControl('syllabusName', new FormControl('', Validators.required), { emitEvent: true });
	}

	/**
	 * Deep copy load of data to the model
	 */
	loadModel(syllabus: Syllabus, reference: string): void {
		console.debug(`${this.constructor.name} - loadModel - modeType=${this.modeType()} - syllabus=`, syllabus);
		this.reference = reference;

		/**
		 * Syllabus details
		 */
		if (this.modeType().match(/^Duplicate/i)) {
			const newName = this.incrementDuplicate(syllabus?.syllabusName ?? '', 'Duplicate');
			syllabus.syllabusName = newName;
			this.form.markAsDirty();
		}

		this.form.patchValue({
			syllabusName: syllabus.syllabusName,
		});

		// set categories by groups
		const categories = structuredClone(syllabus.sortedCategories);
		for (const category of categories) {
			category._id = this.nextId;
		}
		syllabus.categories = categories;
		this.model.set(syllabus);
		this.cdr.detectChanges();
	} // end loadModel

	override preProcessForm(): boolean {
		console.debug(
			`${this.constructor.name} - preProcessForm - modeType=${this.modeType()} - this.model=`,
			this.model(),
		);

		const data = this.model();
		// nothing if no model
		if (!data || !this.form.dirty) {
			this.close();
			return true;
		}

		/**
		 * Scrub
		 */
		data.syllabusName = (data.syllabusName || '').trim();
		console.debug(`${this.constructor.name} - preProcessForm - scrubbed syllabusName=`, data.syllabusName);

		/**
		 * Check for duplicated name
		 */
		console.debug(`${this.constructor.name} - preProcessForm - modeType=${this.modeType()}`);
		if (this.modeType() === 'Duplicate' || this.modeType() === 'Add New') {
			const exists = this.syllabusService.selectSyllabusByName(data.syllabusName);
			if (exists) {
				this.notifyService.error(`Syllabus with name ${data.syllabusName} already exists`);
				return false;
			}
		}

		// load categories
		data.categories = this.displayedCategories();
		console.debug(`${this.constructor.name} - onSubmit - displayedCategories=`, this.displayedCategories());

		/**
		 * Remove duplicated rows
		 */
		const notifyDetails: string[] = [];
		if (Array.isArray(data.categories)) {
			const uniqueCategories: Category[] = [];

			for (const cat of data.categories) {
				// skip if all blank
				if (!(cat.style || cat.soloOrGroup || cat.ageDesc || cat.performanceTime || cat.sections?.length)) continue;

				const index = uniqueCategories.findIndex(
					(item) =>
						item.style === cat.style &&
						item.soloOrGroup === cat.soloOrGroup &&
						item.ageDesc === cat.ageDesc &&
						item.performanceTime === cat.performanceTime &&
						item.sections?.join() === cat.sections?.join(),
				);

				if (index === -1) {
					uniqueCategories.push(cat);
				}
			}

			if (uniqueCategories.length !== data.categories.length) {
				const dupRows = data.categories.length - uniqueCategories.length;
				data.categories = uniqueCategories;
				notifyDetails.push(`Removed ${dupRows} duplicate rows`);
			}
		}

		// remove null array entries
		FT_removeNullArrayEntries(data);
		return true;
	} // end onSubmit

	/**
	 * Process button pressed on table
	 */
	handleTableButtonPressed(buttonPressed: TableButtonPressed<Category>) {
		if (!buttonPressed) return;
		console.debug(`${this.constructor.name} - buttonPressed=`, buttonPressed);

		if (buttonPressed.tableAction?.buttonType === 'button') {
			// set current syllabus if exists
			if (buttonPressed._id) {
				if (buttonPressed.tableAction.buttonName?.match(/^add/i)) {
					this.createNewRow(IdToString(buttonPressed._id));
				} else if (buttonPressed.tableAction.buttonName?.match(/^dup/i)) {
					this.duplicateRow(IdToString(buttonPressed._id));
				} else if (buttonPressed.tableAction.buttonName?.match(/^del/i)) {
					this.deleteRow(IdToString(buttonPressed._id));
				}
			} // end if _id
		} // end if button
	} // end handleTableButtonPressed

	onAddCategoryButton(evt: Event, rowId = '') {
		evt.preventDefault();
		this.createNewRow(rowId);
	}

	createNewRow(afterId: string): void {
		this.insertNewCategoryRow(afterId, new Category({ _id: this.nextId }));
	}

	duplicateRow(id: string): void {
		const row = this.getCategoryRowById(id);
		if (!row) return;

		const duplicatedRow = Object.assign({}, new Category(row));
		duplicatedRow._id = this.nextId;
		this.insertNewCategoryRow(id, duplicatedRow);
	}

	deleteRow(id: string): void {
		const index = this.getCategoryRowIndexById(id);

		if (index === -1) return;
		const startArray = index > 0 ? this.displayedCategories().slice(0, index) : [];
		const endArray = index + 1 < this.displayedCategories().length ? this.displayedCategories().slice(index + 1) : [];
		const categories = startArray.concat(endArray);
		this.displayedCategories.set([...categories]);
		this.form.markAsDirty();
	}

	insertNewCategoryRow(afterId: string, category: Category): void {
		console.debug(`${this.constructor.name} - insertNewCategoryRow afterId=${afterId} - newId=${category._id} `);

		// update existing categories
		let categories = this.displayedCategories();

		if (!afterId) {
			categories.push(category);
		} else {
			const index = this.getCategoryRowIndexById(afterId);
			if (index === -1) {
				categories.push(category);
			} else {
				const startArray = categories.slice(0, index + 1);
				const endArray = categories.slice(index + 1);
				categories = startArray.concat([category], endArray);
			}
		}
		this.displayedCategories.set([...categories]);
		this.form.markAsDirty();
		// this.cdr.markForCheck();
	}

	/**
	 * update table data
	 */
	onChangedColumn(changedColumn: ChangedTableColumnValue<Category>) {
		// update row and column
		const category = this.getCategoryRowById(String(changedColumn.rowId));
		if (!category) return;

		const cellValue = changedColumn.column.inputValue as Category[keyof Category];
		if (cellValue === undefined) return;

		const columnName = changedColumn.column.name as keyof Category;
		const existingValue = category[columnName];

		if (existingValue === cellValue) return;

		if (columnName === 'style') {
			category.style = cellValue as typeof category.style;
			const { compStyles, categoryType, basicStyles } = StandardiseCompStyle(category.style ?? '');

			category._compStyle = Array.isArray(compStyles) ? compStyles : [compStyles];
		}
		if (columnName === 'soloOrGroup') {
			category.soloOrGroup = cellValue as typeof category.soloOrGroup;
			category._compSoloOrGroup = StandardiseCompSoloOrGroup(category.soloOrGroup ?? '');
		}
		if (columnName === 'ageDesc') {
			category.ageDesc = cellValue as typeof category.ageDesc;
			ComputeAgeValues(category);
		}
		if (columnName === 'performanceTime') category.performanceTime = cellValue as typeof category.performanceTime;
		if (columnName === 'sections') {
			category.sections = FT_isEmpty(cellValue) ? [] : (GetAsArray(cellValue as string[]) as typeof category.sections);
		}
		this.form.markAsDirty();

		/**
		 * compute groups values
		 */
		AdjustCategoryAges(this.displayedCategories());
		this.rowsChanged.set(new Date().getTime());
	}

	incrementDuplicate(input: string, counterString = 'Duplicate'): string {
		const counterRegex = new RegExp(`${counterString}`, 'i');
		const counterRegexDigits = new RegExp(`${counterString}\\s*\\d+`, 'i');

		if (!counterRegex.test(input) && !counterRegexDigits.test(input)) {
			return `(${counterString}) ${input}`;
		} else if (counterRegexDigits.test(input)) {
			const counterRegexDigitsReplace = new RegExp(`${counterString} (\\d+)`, 'i');
			return input.replace(counterRegexDigitsReplace, (_match, number) => {
				const incrementedNumber = Number.parseInt(number) + 1;
				return `${counterString} ${incrementedNumber}`;
			});
		} else if (counterRegex.test(input)) {
			return input.replace(counterRegex, `${counterString} 2`);
		} else {
			return input;
		}
	}

	getCategoryRowById(id: string): Category | undefined {
		return this.displayedCategories().find((item) => item._id === id);
	}

	getCategoryRowIndexById(id: string): number {
		return this.displayedCategories().findIndex((item) => item._id === id);
	}
} // end class

const columnDefinitions: TableColumn<Category>[] = [
	new TableColumn({ name: '_id', columnType: 'text', label: '_id', hidden: true, hideShow: false, wrapText: false }),
	new TableColumn({
		name: 'style',
		columnType: 'text',
		label: 'Style',
		hidden: false,
		sticky: true,
		maxWidth: 370,
		minWidth: 270,
		required: true,
		wrapText: false,
		editable: true,
		tooltipColumn: '_compStyle',
	}),

	new TableColumn({
		name: 'soloOrGroup',
		columnType: 'text',
		label: 'Solo/Group',
		hidden: false,
		sticky: false,
		maxWidth: 170,
		minWidth: 70,
		required: false,
		wrapText: false,
		editable: true,
		tooltipColumn: '_compSoloOrGroup',
	}),

	new TableColumn({
		name: 'ageDesc',
		columnType: 'text',
		label: 'Age',
		hidden: false,
		sticky: false,
		maxWidth: 170,
		minWidth: 70,
		required: false,
		wrapText: false,
		editable: true,
		tooltipColumn: '_compRangeDesc',
	}),

	new TableColumn({
		name: 'performanceTime',
		columnType: 'text',
		label: 'Time',
		hidden: false,
		sticky: false,
		maxWidth: 140,
		minWidth: 80,
		required: false,
		wrapText: false,
		editable: true,
	}),

	new TableColumn({
		name: 'sections',
		columnType: 'text',
		label: 'Sections',
		hidden: false,
		sticky: false,
		maxWidth: 160,
		required: false,
		wrapText: true,
		preWrap: 'pre-line',
		editable: true,
		selectOptions: [...Sections],
		selectMultiple: true,
		selectNone: true,
		fontSize: 'small',
		arraySeparator: '\n',
	}),

	new TableColumn({
		name: '_calculated',
		columnType: 'text',
		label: '*Standardised*',
		hidden: false,
		sticky: false,
		fontSize: 'small',
		maxWidth: 240,
		required: false,
		wrapText: true,
		editable: false,
	}),

	new TableColumn({
		name: 'edit',
		columnType: 'actions',
		stickyEnd: true,
		hidden: false,
		label: 'Options',
		align: 'center',
		tableButtons: [
			{
				buttonType: 'button',
				buttonName: 'Duplicate',
				buttonIcon: faClone,
				buttonLabel: 'Duplicate row',
			},

			{
				buttonType: 'button',
				buttonName: 'add',
				buttonIcon: faCirclePlus,
				buttonLabel: 'Add row below',
			},

			{
				buttonType: 'button',
				buttonName: 'Delete',
				buttonIcon: faTrash,
				buttonLabel: 'Delete row',
				hoverColor: 'red',
			},
		],
	}),
];

@let viewModel = modelViewable();
<div class="ft-dialog-component wide display-component" [ngClass]="layout.cssClasses()">
  <!-- card header  -->
  <div
    mat-dialog-title
    class="title-container"
    [ngClass]="layout.cssClasses()"
    [attr.aria-label]="viewModel._id || viewModel.competitionName"
  >
    <div class="title-block" [ngClass]="layout.cssClasses()">
      <img
        mat-card-sm-image
        [src]="competitionLogo()"
        class="competition-card-image"
        [ngStyle]="logoStyles()"
        alt="Competition Logo"
      />
      <h4 class="competition-name" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <!-- content  -->
  <mat-dialog-content class="content-container" #dialogcontent [ngClass]="layout.cssClasses()">
    <!-- competition dates  -->
    @if (viewModel._competitionDatesDisplay) {
      <div class="row-container center-on-small">
        <div class="row-header">Competition Dates</div>
        <div class="row-details">
          {{ viewModel._competitionDatesDisplay.replaceAll('\n', ';  ') }}
        </div>
      </div>
    }

    <!-- entries open and close dates  -->
    @if (viewModel._entriesOpenDisplay || viewModel._entriesCloseDisplay) {
      <div class="row-container center-on-small">
        <div class="row-header">Entry Dates</div>
        <div class="row-details">
          {{ viewModel._entriesMinMaxDisplay }}
        </div>

        @if (!layout.isLT_medium()) {
          <ng-container *ngTemplateOutlet="additionalDates"></ng-container>
        }
      </div>
      @if (layout.isLT_medium()) {
        <ng-container *ngTemplateOutlet="additionalDates"></ng-container>
      }
    }

    <!-- music submission -->
    @if (viewModel._musicSubmissionDisplay) {
      <div class="row-container vertical-on-small">
        <div class="row-header">Music Submission</div>
        <div class="row-details">{{ viewModel._musicSubmissionDisplay }}</div>
      </div>
    }

    <!-- information block -->
    <div class="info-block background-accent-light" [ngClass]="layout.cssClasses()">
      <!-- venue name and location -->
      <ng-container *ngTemplateOutlet="venue"></ng-container>

      <!-- completition links  -->
      @if (viewModel.links?.length) {
        <div class="row-container no-separator vertical-on-small">
          @if (!layout.isLT_medium()) {
            <div class="row-header">Links</div>
          }
          <div class="row-details link-buttons hide-scrollbars">
            @for (link of viewModel.links; track $index) {
              @if (link.desc || link.url) {
                @if (getScrubbedLink(link.url, link.desc)) {
                  <a [href]="getScrubbedLink(link.url, link.desc)" target="_blank" class="link-button">
                    <button mat-raised-button>{{ link.desc || link.url }}</button></a
                  >
                } @else {
                  <button mat-raised-button>{{ link.desc || link.url }}</button>
                }
              }
            }
          </div>
        </div>
      }

      <!-- additional information  -->
      @if (viewModel.additionalInfo) {
        <div class="row-container vertical-on-small">
          <div class="row-header small">Additional Info</div>
          <div class="row-details small">{{ viewModel.additionalInfo }}</div>
        </div>
      }

      <!-- age cutoff  -->
      @if (showAgeCutoff()) {
        @if (viewModel.ageCutoff !== 'Other') {
          <div class="row-container vertical-on-small">
            <div class="row-header">Age Cutoff</div>
            <div class="row-details">{{ viewModel.ageCutoff }}</div>
          </div>
        }
        @if (viewModel.ageCutoffNote) {
          <div class="row-container no-separator vertical-on-small">
            @if (!layout.isLT_medium()) {
              <div class="row-header small">Cutoff notes</div>
            }
            <div class="row-details small">{{ viewModel.ageCutoffNote }}</div>
          </div>
        }
      }

      <!-- fees  -->
      @if (showFees()) {
        @if (viewModel._competitionFeesDisplay) {
          <div class="row-container vertical-on-small">
            <div class="row-header">Fees</div>
            <div class="row-details">{{ viewModel._competitionFeesDisplay }}</div>
          </div>
        }
        @if (viewModel._feesNotesDisplay) {
          <div class="row-container no-separator vertical-on-small">
            @if (!layout.isLT_medium()) {
              <div class="row-header small">Fees notes</div>
            }
            <div class="row-details small">{{ viewModel._feesNotesDisplay }}</div>
          </div>
        }
      }

      <!-- prizes  -->
      @if (showPrizes()) {
        <div class="row-container vertical-on-small">
          <div class="row-header">Prizes</div>
          <div class="row-details">{{ viewModel.prizes }}</div>
        </div>
      }
    </div>

    <!-- syllabus  -->
    <div class="syllabus-heading">
      @if (viewModel._syllabus) {
        @if (hasStandardStyles()) {
          <h2 class="syllabus-title">* * * Syllabus * * *</h2>
          <ft-syllabus-groups
            class="syllabus-container"
            categoryType="Standard"
            [syllabus]="viewModel._syllabus!"
            [ngClass]="layout.cssClasses()"
          ></ft-syllabus-groups>
        }
        @if (hasImprovisationStyles()) {
          <h2 class="syllabus-title">* * * Improvisation Categories * * *</h2>
          <ft-syllabus-groups
            class="syllabus-container"
            categoryType="Improvisation"
            [syllabus]="viewModel._syllabus!"
            [ngClass]="layout.cssClasses()"
          ></ft-syllabus-groups>
        }
        @if (hasDisabilitiesStyles()) {
          <h2 class="syllabus-title">* * * Dancers With Disabilities Categories * * *</h2>
          <ft-syllabus-groups
            class="syllabus-container"
            categoryType="Dancers with Disabilities"
            [syllabus]="viewModel._syllabus!"
            [ngClass]="layout.cssClasses()"
          ></ft-syllabus-groups>
        }
        @if (hasChampionshipStyles()) {
          <h2 class="syllabus-title">* * * Championship Categories * * *</h2>
          <ft-syllabus-groups
            class="syllabus-container"
            categoryType="Championship"
            [syllabus]="viewModel._syllabus!"
            [ngClass]="layout.cssClasses()"
          ></ft-syllabus-groups>
        }
        @if (hasScholarshipStyles()) {
          <h2 class="syllabus-title">* * * Scholarship Categories * * *</h2>
          <ft-syllabus-groups
            class="syllabus-container"
            categoryType="Scholarship"
            [syllabus]="viewModel._syllabus!"
            [ngClass]="layout.cssClasses()"
          ></ft-syllabus-groups>
        }
      } @else {
        <h5 class="cursive-font">* {{ noSyllabusText() }} *</h5>
      }
    </div>

    <form [formGroup]="form" class="card-dialog__form" (ngSubmit)="close()">
      <div class="card-dialog__form-content hide-scrollbars"></div>
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="competition-actions">
    <button type="submit" mat-button class="submit-button" (click)="onSubmit()">
      {{ submitTitle() }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template #additionalDates>
  @if (showAdditionalDates()) {
    <div class="row-box" [ngClass]="layout.cssClasses()">
      @if (showEntriesOpen()) {
        <div class="box-row">
          <div class="box-header">Entries Open</div>
          <div class="box-details">{{ entriesOpenDisplay() }}</div>
        </div>
      }
      @if (showEntriesClose()) {
        <div class="box-row">
          <div class="box-header">Entries Close</div>
          <div class="box-details">{{ entriesCloseDisplay() }}</div>
        </div>
      }
      @if (showEntriesLateClose()) {
        <div class="box-row">
          <div class="box-header">Entries Late Close</div>
          <div class="box-details">{{ entriesLateCloseDisplay() }}</div>
        </div>
      }
    </div>
  }
</ng-template>

<ng-template #venue>
  @if (showVenue()) {
    <div
      class="row-container center-on-small no-border flex-wrap"
      [ngClass]="layout.isLT_medium() ? 'flex-column' : ''"
    >
      <div class="row-header">Venue</div>
      @if (viewModel.venueName) {
        <div class="row-details strong no-flex">{{ viewModel.venueName }}</div>
      }
      @if (viewModel.venueLocation && layout.isLT_medium()) {
        <div class="row-details">{{ viewModel.venueLocation }}</div>
      }
      @if (viewModel.venueLocation && !layout.isLT_medium()) {
        <div class="row-box" [ngClass]="layout.cssClasses()">
          <div class="box-row">
            <div class="box-header">Location</div>
            <div class="box-details">{{ viewModel.venueLocation }}</div>
          </div>
        </div>
      }
    </div>
  }
</ng-template>

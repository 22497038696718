import { GetFirstMatchingStylePattern } from './index.js';
import { GetSoloOrGroupPatterns } from '../style-patterns/index.js';
export const CompSoloOrGroups = ['Solo', 'Duo/Trio/Quad', 'Group', 'Small Group', 'Other'];
export const StandardiseCompSoloOrGroup = soloOrGroup => {
  if (!soloOrGroup) return 'Other';
  const soloOrGroupPatterns = GetSoloOrGroupPatterns();
  if (!soloOrGroupPatterns.length) {
    console.error(`❌ StandardiseCompSoloOrGroup - no soloOrGroupPatterns - have you SetStylePatterns?`);
    return 'Other';
  }
  const noCrapPattern = /\W+/g;
  const testSoloOrGroup = String(soloOrGroup).toLowerCase().replace(noCrapPattern, '');
  /**
   * Match solo or group
   */
  const matchingSolorOrGroupPattern = GetFirstMatchingStylePattern(soloOrGroupPatterns, testSoloOrGroup);
  if (matchingSolorOrGroupPattern?.compSoloOrGroup) {
    return matchingSolorOrGroupPattern.compSoloOrGroup;
  } else {
    console.error(`📣 StandardiseCompSoloOrGroup - no soloOrGroupPatterns found for soloOrGroup=${soloOrGroup}, testSoloOrGroup=${testSoloOrGroup}, matchingSolorOrGroupPattern=`, matchingSolorOrGroupPattern);
    return 'Other';
  }
}; // end StandardiseCompeSoloOrGroup

<div class="ft-dialog-component syllabus-card" resizeObserver (resize)="onResize($event)">
  <!-- loading bar -->
  <ft-progress-bar [isLoading]="rowsLoading()"></ft-progress-bar>

  <!-- card header  -->
  <div mat-dialog-title>
    <div class="title">
      <fa-icon [icon]="faCubesStacked"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        {{ headerTitle() }}
        @if (documentTitle()) {
          <span class="color-primary heading-font">: {{ documentTitle() }}</span>
        }
      </h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>
  <!--  mat-typography -->
  <mat-dialog-content class="syllabus-content">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="hide-scrollbars">
      <mat-form-field appearance="fill" class="syllabus-name width-100">
        <mat-label>Syllabus name</mat-label>
        <input matInput formControlName="syllabusName" placeholder="Syllabus name" required />
      </mat-form-field>

      <div class="categories-header-row width-100">
        <div class="info-string mat-subheading-1 body-font">
          Number of categories: {{ displayedCategories().length }}
        </div>
        <div class="info-string mat-subheading-2 body-font">
          Competitions using:
          {{ syllabusCount(model()?._id ?? '') }}
        </div>

        <div class="button-instance add" (click)="onAddCategoryButton($event)">
          <button mat-icon-button class="add" color="primary" aria-label="Add new category">
            <fa-icon [icon]="faCirclePlus"></fa-icon>
          </button>
          <p class="color-primary" color="primary">Add new category</p>
        </div>
      </div>

      <div
        class="category-table-container"
        tabindex="0"
        [attr.data-table-height]="tableHeightPx()"
        getHeight
        querySelector=".cdk-row, .cdk-header-row"
        [expectedElements]="displayedCategories().length"
        (heightResults)="onHeightResults($event)"
        #heightGetter="getHeight"
      >
        <ft-display-table
          [columns]="columns"
          [databaseRows]="displayedCategories()"
          [dataRowsChanged]="rowsChanged()"
          (buttonPressed)="handleTableButtonPressed($event)"
          rowId="_id"
          (filteredRowCount)="rowsShowing.set($event)"
          (changedColumn)="onChangedColumn($event)"
        ></ft-display-table>
      </div>
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="syllabus-actions">
    <button
      type="submit"
      mat-button
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="!form.valid || !form.dirty"
      aria-label="Submit"
    >
      {{ form.valid || form.pristine || isInfoModeType() ? submitTitle() : 'Errors Exist' }}
    </button>
  </mat-dialog-actions>
  <!-- </div> -->
</div>

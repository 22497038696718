import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	type ElementRef,
	type OnInit,
	type QueryList,
	computed,
	inject,
	input,
	viewChildren,
} from '@angular/core';

import { NgClass } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { SyllabusService } from '@danceShared/syllabuses-shared/data/syllabus.service';
import { Syllabus } from '@danceShared/syllabuses-shared/syllabus.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { CategoryType, StyleGroup } from '@furnas-technology/common-library/categories';

type StyleGroupsDisplay = { [key in CategoryType]: StyleGroup[] };

@Component({
	selector: 'ft-syllabus-groups',
	templateUrl: './syllabus-groups.component.html',
	styleUrls: ['./syllabus-groups.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [FontAwesomeModule, NgClass],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyllabusGroupsComponent implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);

	syllabusService = inject(SyllabusService);

	syllabus = input.required<Syllabus>();
	categoryType = input<CategoryType>();

	styles = viewChildren<QueryList<ElementRef>>('style');
	categorylist = viewChildren<QueryList<ElementRef>>('categorylist');

	styleGroups = computed<StyleGroup[]>(() => {
		return this.syllabus()._styleGroups.filter(
			(group) => !!(!this.categoryType() || group.categoryType === this.categoryType()),
		);
	});

	sectionJoin = computed(() => {
		return this.layout.isLT_medium() ? ', ' : '\r\n';
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	async ngOnInit() {
		console.debug(`${this.constructor.name} - syllabus - syllabus=`, this.syllabus());
	}

	ngAfterViewInit() {}
} // end class
